import * as React from "react";
// import dayjs, { Dayjs } from "dayjs";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { TimeField } from "@mui/x-date-pickers/TimeField";
import { Box, Typography, useTheme, Button } from "@mui/material";
import Papa from "papaparse";
import SchoolIcon from "@mui/icons-material/School";
import { authAPI } from "../axios/authAPI";
import StatusField from "../components/statusField/StatusField";

const SetSchoolTime = () => {
  const theme = useTheme();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [fetchData, setFetchData] = React.useState({});
  const [openStatus, setOpenStatus] = React.useState(false);
  const [statusType, setStatusType] = React.useState();
  const [statusText, setStatusText] = React.useState();

  const [value, setValue] = React.useState({
    Morning_Start: "",
    Morning_End: "",
    Evening_Start: "",
    Evening_End: "",
  });
  const [flag, setFlag] = React.useState(true);

  React.useEffect(() => {
    try {
      const fetchTimeData = async () => {
        const fetchData = await authAPI.get(
          `/api/school-timings?userid=${userDetails?.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        const responseData = await fetchData?.data;
        // console.log(responseData);
        // const newData = responseData.map((item) => ({
        //   ...item,

        //   Morning_Start: item?.Morning_Start,
        //   Morning_End: item?.Morning_End,
        //   Evening_Start: item?.Evening_Start,
        //   Evening_End: item?.Evening_End,
        // }));
        setFetchData(...responseData);
        setValue(...responseData);
      };

      fetchTimeData().catch((err) => {
        console.log(err?.response?.data?.error?.message);
        setStatusText(err?.response?.data?.error?.message);
        setStatusType("warning");
        setOpenStatus(true);
      });
    } catch (error) {
      console.log(error);
    }
  }, [flag]);

  const handleTime = async () => {
    const timeData = {
      Morning_Start: value?.Morning_Start,
      Morning_End: value?.Morning_End,
      Evening_Start: value?.Evening_Start,
      Evening_End: value?.Evening_End,
      // schoolId: parseInt(userDetails?.id, 10),
      // school: parseInt(userDetails?.id, 10),
      userID: userDetails?.id,
      publishedAt: Date.now(),
    };
    if (fetchData === undefined) {
      authAPI
        .post(
          `/api/school-timings`,
          { data: timeData },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {
          setStatusText("Timings have been updated");
          setStatusType("success");
          setOpenStatus(true);
        })
        .catch((err) => console.log(err));
    } else {
      authAPI
        .put(
          `/api/school-timings/${value?.id}`,
          { data: timeData },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {
          setStatusText("Timings have been updated");
          setStatusType("success");
          setOpenStatus(true);
        })
        .catch((err) => console.log(err));
    }

    // setFlag(!flag);
  };
  const style = {
    border: "0.5px solid lightgray",
    borderRadius: "5%",
    backgroundColor: "#f9fafb",
    width: "10rem",
    height: "2.5rem",
    margin: "0 0.5rem",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
        height: "18vh",

        justifyContent: "space-between",
      }}
      m="10px"
    >
      <Box>
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          Add Timing
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography> */}
      </Box>
      <Box marginTop="2rem" margin>
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="column">
            <p style={{ marginLeft: "0.7rem" }}>Morning Start</p>
            <input
              type="time"
              value={value?.Morning_Start}
              style={style}
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  Morning_Start: e.target.value,
                }))
              }
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <p style={{ marginLeft: "0.7rem" }}>Morning End</p>

            <input
              type="time"
              value={value?.Morning_End}
              style={style}
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  Morning_End: e.target.value,
                }))
              }
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" marginTop="1rem">
          <Box display="flex" flexDirection="column">
            <p style={{ marginLeft: "0.7rem" }}>Evening Start</p>

            <input
              type="time"
              value={value?.Evening_Start}
              style={style}
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  Evening_Start: e.target.value,
                }))
              }
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <p style={{ marginLeft: "0.7rem" }}>Evening End</p>

            <input
              type="time"
              value={value?.Evening_End}
              style={style}
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  Evening_End: e.target.value,
                }))
              }
            />
          </Box>
        </Box>
      </Box>

      <Box m="15px">
        <Button variant="contained" onClick={handleTime}>
          Update
        </Button>
      </Box>
      {openStatus && (
        <StatusField
          setOpenStatus={setOpenStatus}
          statusType={statusType}
          statusText={statusText}
        />
      )}
    </Box>
  );
};

export default SetSchoolTime;
