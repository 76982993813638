import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  styled,
  useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SchoolIcon from "@mui/icons-material/School";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { useFormik } from "formik";
import { authAPI } from "../../axios/authAPI";

export default function AttendanceReport() {
  const [monthlyData, setMonthlyData] = useState([]);
  const [value, setValue] = React.useState(null);
  const [textMessage, setTextMessage] = useState(
    "Fill up the details to get Monthly data"
  );
  const [loader, setLoader] = useState(false);
  const [classList, setClassList] = useState([]);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [sectonList, setSectionList] = useState([]);
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const MainContainerStyle = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      "& > div": {
        width: "100%",
        marginTop: 15,
        "&.classList": {
          "& > div": {
            marginTop: 15,
          },
        },
        "& > button": {
          padding: "6px 6px",
          margin: "0px 2px",
        },
        "& > div": {
          width: "100%",
        },
      },
    },
  }));
  const [show, setShow] = useState(false);
  const initialValues = {
    Sections: sectonList[0],
    Class: classList[0],
    DateField: value,
  };

  useEffect(() => {
    const fetchClassData = async () => {
      const fetchdata = await authAPI.get(
        `/api/classes?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const responseData = await fetchdata?.data;
      // const idMax = responseData.map((x) => x?.id);

      // const allID = {
      //   ClassName: "All",
      //   id: Math.max(...idMax) + 1,
      //   userID: userDetails?.id,
      // };

      // responseData.unshift(allID);
      setClassList(responseData);
    };
    fetchClassData();
  }, []);

  useEffect(() => {
    const fetchSectionData = async () => {
      const fetchdata = await authAPI.get(
        `/api/sections?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const responseData = await fetchdata?.data;
      // const idMax = responseData.map((x) => x?.id);

      // const allID = {
      //   sectionName: "All",
      //   id: Math.max(...idMax) + 1,
      //   userID: userDetails?.id,
      // };

      // responseData.unshift(allID);
      setSectionList(responseData);
    };
    fetchSectionData();
  }, []);

  const formik = useFormik({
    initialValues,
    // validationSchema: userSchema,
    onSubmit: (values) => {
      if (values?.Class === undefined || values?.Sections === undefined)
        return alert("Please select the values !!!");
      // values.DateField = moment
      //   .utc(values.DateField?.$d)
      //   .local()
      //   .format("YYYY-MM");
      // values.startDate = `${values?.DateField}-01`;
      // values.endDate = `${values?.DateField}-31`;
      // console.log(
      //   `${moment
      //     .utc(values?.DateField?.$d)
      //     .local()
      //     .format("YYYY-MM")}-${moment(
      //     moment.utc(values?.DateField?.$d).local().format("YYYY-MM")
      //   ).daysInMonth()}`
      // );
      // console.log(
      //   moment(
      //     moment.utc(values?.DateField?.$d).local().format("YYYY-MM")
      //   ).daysInMonth()
      // );

      setLoader(true);
      authAPI
        .get(
          `/api/get-monthly-reports?startdate=${moment
            .utc(values?.DateField?.$d)
            .local()
            .format("YYYY-MM")}-01&enddate=${moment
            .utc(values?.DateField?.$d)
            .local()
            .format("YYYY-MM")}-${moment(
            moment.utc(values?.DateField?.$d).local().format("YYYY-MM")
          ).daysInMonth()}&section=${values?.Sections}&classes=${
            values?.Class
          }&userid=5`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {
          // console.log(res?.data);
          setLoader(false);
          setMonthlyData(res?.data);
          if (res?.data.length === 0) {
            setShow(false);
            setTextMessage("NO data found for the required month !!");
          } else {
            setShow(true);
          }
        })
        .catch((err) => {
          setLoader(false);
          setTextMessage("Please try again after some time !!!!");
          console.log(err);
        });
    },
  });

  const getDayName = (dateStr, locale) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: "long" });
  };

  const getPercentage = (total, present) => {
    const per = (present / total) * 100;
    return Math.round(per);
  };

  // useEffect(() => {
  //   const liveAttendanceData = async () => {
  //     const fetchDataLive = await authAPI.get(
  //       `/api/get-monthly-reports?startdate=2023-05-01&enddate=2023-05-31&section=A&classes=I&userid=5`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  //         },
  //       }
  //     );

  //     setMonthlyData(fetchDataLive?.data);
  //   };

  //   liveAttendanceData();
  // }, []);

  return (
    <Box m="20px">
      <Box>
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          Student Attendance Report
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
      {subtitle}
    </Typography> */}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <MainContainerStyle
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            gap="0.75rem"
            flexDirection={isNonMobile ? "row" : "column"}
          >
            <Box>
              <FormControl
                sx={{
                  width: isNonMobile ? "10rem" : "100%",
                  marginTop: "0.5rem",
                  marginRight: "1rem",
                }}
              >
                <InputLabel id="Class">Class</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="Class"
                  name="Class"
                  value={formik.values.Class}
                  label="Class"
                  onChange={formik.handleChange}
                  // error={formik.touched.Sections && Boolean(formik.errors.Sections)}
                  // helperText={formik.touched.Sections && formik.errors.Sections}
                >
                  {classList.map((c) => (
                    <MenuItem value={c?.ClassName} key={c?.id}>
                      {c?.ClassName}
                    </MenuItem>
                  ))}

                  {/* <MenuItem value={"20"}>Twenty</MenuItem>
              <MenuItem value={"30"}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl
                sx={{
                  width: isNonMobile ? "10rem" : "100%",
                  marginTop: "0.5rem",
                }}
              >
                <InputLabel id="demo-simple-select-label">Sections</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="Sections"
                  name="Sections"
                  value={formik.values.Sections}
                  label="Sections"
                  onChange={formik.handleChange}
                  // error={formik.touched.Sections && Boolean(formik.errors.Sections)}
                  // helperText={formik.touched.Sections && formik.errors.Sections}
                >
                  {sectonList.map((c) => (
                    <MenuItem value={c?.sectionName} key={c?.id}>
                      {c?.sectionName}
                    </MenuItem>
                  ))}

                  {/* <MenuItem value={"20"}>Twenty</MenuItem>
              <MenuItem value={"30"}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Please select date"
                    value={formik.values.DateField}
                    onChange={(value) =>
                      formik.setFieldValue("DateField", value, true)
                    }
                    views={["year", "month"]}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Box>

          <Box
            width="25rem"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <LoadingButton
              variant="contained"
              loading={loader}
              loadingPosition="start"
              sx={{ width: "7rem", height: "3rem", marginTop: "0.5rem" }}
              type="submit"
            >
              Submit
            </LoadingButton>
            {/* <Button
              variant="contained"
              sx={{ width: "7rem", height: "3rem", marginTop: "0.5rem" }}
            >
              Export PNG
            </Button>
            <Button
              variant="contained"
              sx={{ width: "7rem", height: "3rem", marginTop: "0.5rem" }}
            >
              Print
            </Button> */}
          </Box>
        </MainContainerStyle>
      </form>
      {show ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                {monthlyData.length !== 0 &&
                  monthlyData[0].map((r) => (
                    <TableCell align="center">
                      {r?.data.split("-")[2]}{" "}
                      {getDayName(r?.data, "en-EN").substring(0, 3)}
                    </TableCell>
                  ))}
                <TableCell>Out Of</TableCell>
                <TableCell>%</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {monthlyData?.map((row) => (
                <TableRow
                  key={row[0].Name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row[0].Name.substring(0, 10)}
                  </TableCell>
                  {row.map((item) => (
                    <TableCell align="center">
                      {getDayName(item?.data, "en-EN").substring(0, 3) === "Sun"
                        ? ""
                        : item.Attendance}
                    </TableCell>
                  ))}

                  <TableCell>
                    {row?.filter((x) => x.Attendance === "P").length}/
                    {
                      row.filter(
                        (x) =>
                          getDayName(x?.data, "en-EN").substring(0, 3) !== "Sun"
                      ).length
                    }
                  </TableCell>
                  <TableCell>
                    {getPercentage(
                      row.filter(
                        (x) =>
                          getDayName(x?.data, "en-EN").substring(0, 3) !== "Sun"
                      ).length,
                      row?.filter((x) => x.Attendance === "P").length
                    )}
                    %
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <p style={{ margin: "1rem 0" }}>{textMessage}</p>
        </>
      )}
    </Box>
  );
}
