import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Alert,
  Box,
  Typography,
  styled,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { authAPI } from "../axios/authAPI";

const theme = createTheme();
const MainContainerStyle = styled(Box)(({ theme }) => ({
  height: "100vh",
  backgroundColor: theme.palette.secondary.dark,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 25px",
  "& .loginBox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: "15px",
    padding: 25,
    "& > h4": {
      paddingBottom: 30,
    },
  },
}));
export default function SignIn() {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errText, setErrText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      navigate("/dashboard/app");
    }
  }, []);

  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };
  //   const handleSubmit = (event) => {
  //     event.preventDefault();
  //     const data = new FormData(event.currentTarget);
  //     console.log({
  //       email: data.get("email"),
  //       password: data.get("password"),
  //     });
  //   };
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwiaWF0IjoxNjc4MjY4MDE2LCJleHAiOjE2ODA4NjAwMTZ9.UsJBjcjt-E6ylOXW7VOe3qfyw1MXCmCz5ceWySpFvDo";
  const validationSchemaObject = yup.object({
    identifier: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      identifier: "",
      password: "",
    },
    validationSchema: validationSchemaObject,
    onSubmit: async (values) => {
      setShowError(false);

      try {
        const firstRes = await authAPI.post(`/api/auth/local`, values);

        localStorage.setItem(
          "userDetails",
          JSON.stringify(firstRes?.data?.user)
        );
        localStorage.setItem("jwt", firstRes?.data?.jwt);
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));

        if (firstRes?.data?.jwt) {
          const secondRes = await authAPI.get(`api/users/${userDetails.id}`, {
            headers: {
              Authorization: `Bearer ${firstRes?.data?.jwt}`,
            },
          });

          localStorage.setItem("userDetails", JSON.stringify(secondRes?.data));

          navigate("/dashboard/app");
        }
      } catch (error) {
        setShowError(true);
        setErrText(error?.response?.data?.error?.message);
      }
      // authAPI
      //   .post(`/api/auth/local`, values)
      //   .then(async (res) => {
      //     console.log(res);
      //     localStorage.setItem("jwt", res?.data?.jwt);
      //     localStorage.setItem("userDetails", JSON.stringify(res?.data?.user));
      //     navigate("/dashboard/app");
      //   })
      //   .catch((err) => {
      //     console.log(err?.response?.data?.error?.message);
      //     setShowError(true);
      //     setErrText(err?.response?.data?.error?.message);
      //   });
    },
    // async (values) => {
    //   const response = await fetch("http://localhost:1337/api/auth/local", {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(values),
    //   });
    //   // const responseData = await response
    //   console.log(response);
    //   //   alert(JSON.stringify(values, null, 2));
    //   //   authAPI
    //   //     .post(`/api/auth/local`, { identifier, password })
    //   //     .then(async (res) => {
    //   //       console.log(res);
    //   //     })
    //   //     .catch((err) => {
    //   //       console.log(err);
    //   //     });
    // },
  });

  return (
    // <ThemeProvider theme={theme}>
    //   <Container component="main" maxWidth="xs">
    //     <CssBaseline />
    <MainContainerStyle>
      <Box className="loginBox">
        {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}

        <Typography variant="h4" color="black" align="center">
          Attendance Management System
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="identifier"
            name="identifier"
            label="Email"
            value={formik.values.identifier}
            onChange={formik.handleChange}
            error={
              formik.touched.identifier && Boolean(formik.errors.identifier)
            }
            helperText={formik.touched.identifier && formik.errors.identifier}
            autoFocus
            sx={{
              marginBottom: "2rem",
              color: "white",
            }}
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Password"
            type={passwordVisibility ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            sx={{
              color: "white",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3.5, mb: 2, padding: "10px" }}
          >
            Sign In
          </Button>
        </form>
        {showError && (
          <Alert variant="standard" severity="error">
            {errText}
          </Alert>
        )}
        {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
      </Box>
    </MainContainerStyle>
  );
}
