import { faker } from "@faker-js/faker";
// @mui
import { useTheme, alpha } from "@mui/material/styles";
import { Grid, Box, Typography } from "@mui/material";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import AutorenewSharpIcon from "@mui/icons-material/AutorenewSharp";
// import { alpha } from "@mui/material/styles";
// components
import Page from "../components/Page";
import DashboardTable from "../layouts/dashboard/DashboardTable";

import Iconify from "../components/Iconify";
// sections
// import {
//   AppTasks,
//   AppNewsUpdate,
//   AppOrderTimeline,
//   AppCurrentVisits,
//   AppWebsiteVisits,
//   AppTrafficBySite,
//   AppWidgetSummary,
//   AppCurrentSubject,
//   AppConversionRates,
// } from "../sections/@dashboard/app";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();

  return (
    <Page title="Dashboard">
      <Box sx={{ padding: "0 20px" }}>
        <Grid
          container
          sx={{
            border: "1px solid #cbc9c9",
            borderRadius: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              // border: "1px solid black",
              padding: "1%",
              display: "flex",
              alignItems: "center",
              // backgroundColor: "#e6e6e6",
            }}
            borderBottom="1px solid #e6e6e6"
          >
            <ViewCompactIcon sx={{ fontSize: 30 }} />
            <Typography> Today's Summary</Typography>
          </Grid>

          <Grid item xs={12}>
            <DashboardTable />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
}
