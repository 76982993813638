import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  styled,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import SchoolIcon from "@mui/icons-material/School";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import { authAPI } from "../../axios/authAPI";
import templateData from "../../data/templateData";

const SendManual = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:500px)");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [classList, setClassList] = useState(["All"]);
  const [sectonList, setSectionList] = useState(["All"]);
  const [loader, setLoader] = useState(false);
  const [showLoaderMessage, setShowLoaderMessage] = useState({
    showLoader: true,
    message:
      "Do not press back button or reload the page while messages are being sent. It'll take some time :)",
  });
  const [SMSbutton, setSMSbutton] = useState(true);

  const { search } = useLocation();

  const [formValues, setFormValues] = useState(null);

  const requiredTemplate = templateData.find(
    (x) => x.id === search.split("=")[1]
  );
  const check = requiredTemplate?.content.split(" ");
  const res = check.filter(
    (x) => x !== "{#var#}" && x !== "{#var#}," && x !== "{#var#}."
  );
  console.log(res);
  useEffect(() => {
    const fetchClassData = async () => {
      const fetchdata = await authAPI.get(
        `/api/classes?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const responseData = await fetchdata?.data;
      const idMax = responseData.map((x) => x?.id);

      const allID = {
        ClassName: "All",
        id: Math.max(...idMax) + 1,
        userID: userDetails?.id,
      };

      responseData.unshift(allID);

      setClassList(responseData);
    };
    fetchClassData();
  }, []);

  useEffect(() => {
    const fetchSectionData = async () => {
      const fetchdata = await authAPI.get(
        `/api/sections?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      const responseData = await fetchdata?.data;

      const idMax = responseData.map((x) => x?.id);

      const allID = {
        sectionName: "All",
        id: Math.max(...idMax) + 1,
        userID: userDetails?.id,
      };

      responseData.unshift(allID);
      setSectionList(responseData);
    };
    fetchSectionData();
  }, []);

  const initialValues = {
    sections: sectonList[0],
    classes: classList[0],
    // Attendance: studentSelection[0],

    message: requiredTemplate?.content,
  };
  const userSchema = yup.object({
    message: yup
      .string()
      .required("required")
      .test(
        "no-var",
        'The message must not contain "{#var#}".',
        (value) => !value || !value.includes("{#var#}")
      ),
    // .matches(/\{#var#\}/, "not in a format"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: (values) => {
      setLoader(false);
      values.type = search.split("=")[1];
      values.userId = userDetails?.id;
      setFormValues(values);
      setSMSbutton(false);
    },
  });
  console.log(formik.errors);
  const handleSendSMS = () => {
    setShowLoaderMessage((prev) => ({
      ...prev,
      showLoader: true,
      message:
        "Do not press back button or reload the page while messages are being sent. It'll take some time :)",
    }));
    setLoader(true);

    authAPI
      .post(`/api/send-sms-notification`, formValues, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((res) => {
        setShowLoaderMessage((prev) => ({
          ...prev,
          showLoader: false,
          message: "Message has been sent successfully !!!",
        }));
        setSMSbutton(true);
      })
      .catch((err) => {
        setShowLoaderMessage((prev) => ({
          ...prev,
          showLoader: false,
          message: "Uh Oh!!, We are facing some issue, please try again later.",
        }));
        setSMSbutton(true);
      });
  };

  return (
    <Box m="0 20px 20px 20px">
      <Box>
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          Send SMS Notifications
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
      {subtitle}
    </Typography> */}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column" mt="1.5rem">
          <Box display="flex" gap="1rem">
            <Box>
              <FormControl sx={{ width: isNonMobile ? "10rem" : "6.5rem" }}>
                <InputLabel id="demo-simple-select-label">Class</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="classes"
                  name="classes"
                  value={formik.values.classes}
                  label="Class"
                  onChange={formik.handleChange}
                  // error={formik.touched.Class && Boolean(formik.errors.Class)}
                  // helperText={formik.touched.Class && formik.errors.Class}
                >
                  {classList.map((c) => (
                    <MenuItem value={c?.ClassName} key={c?.id}>
                      {c?.ClassName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={{ width: isNonMobile ? "10rem" : "6.5rem" }}>
                <InputLabel id="demo-simple-select-label">Sections</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="sections"
                  name="sections"
                  value={formik.values.sections}
                  label="Section"
                  onChange={formik.handleChange}
                  // error={formik.touched.Class && Boolean(formik.errors.Class)}
                  // helperText={formik.touched.Class && formik.errors.Class}
                >
                  {sectonList.map((c) => (
                    <MenuItem value={c?.sectionName} key={c?.id}>
                      {c?.sectionName}
                    </MenuItem>
                  ))}

                  {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="5px">
            <TextField
              // fullWidth
              id="message"
              name="message"
              multiline
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
              placeholder="Write your template here...."
              // autoFocus
              sx={{
                width: isNonMobile ? "32rem" : "21.5rem",
                marginTop: "1rem",
              }}
            />
            <Box display="flex" flexDirection="column">
              <p
                style={{ color: theme.palette.grey[600], fontStyle: "italic" }}
              >
                Format:
              </p>
              <TextField
                // fullWidth

                multiline
                rows={4}
                value={requiredTemplate?.example}
                // autoFocus
                sx={{
                  "& .MuiInputBase-root": {
                    color: theme.palette.grey[600],
                  },
                  width: isNonMobile ? "32rem" : "21.5rem",
                  marginTop: "1rem",
                  fontStyle: "italic",

                  backgroundColor: theme.palette.grey[300],
                }}
                disable
                // color={theme.palette.grey[900]}
              />
            </Box>
          </Box>
          <Box display="flex" gap="1rem">
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "7.2rem", marginTop: "1rem" }}
            >
              Review SMS
            </Button>
            <Button
              type="button"
              variant="contained"
              sx={{ width: "7.2rem", marginTop: "1rem" }}
              disabled={SMSbutton}
              onClick={() => handleSendSMS()}
            >
              Send SMS
            </Button>
          </Box>
        </Box>
      </form>
      {formValues !== null &&
        (loader === true ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "5rem 0" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="10px"
            >
              {showLoaderMessage?.showLoader ? (
                <>
                  <CircularProgress />
                  <p>{showLoaderMessage?.message}</p>
                </>
              ) : (
                <>
                  <DoneAllIcon sx={{ color: "green", fontSize: "3rem" }} />
                  <p>{showLoaderMessage?.message}</p>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography sx={{ m: "10px 0", fontStyle: "italic" }}>
              Generated message
            </Typography>
            <Box>
              <Typography
                variant="h6"
                color={theme.palette.grey[900]}
                sx={{ fontWeight: "regular" }}
              >
                Class : {formValues?.classes}
              </Typography>
              <Typography
                variant="h6"
                color={theme.palette.grey[900]}
                sx={{ fontWeight: "regular" }}
              >
                Section : {formValues?.sections}
              </Typography>
              <Typography
                variant="h3"
                color={theme.palette.grey[700]}
                fontWeight="bold"
                style={{ wordWrap: "break-word" }}
              >
                {formValues?.message}
              </Typography>
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default SendManual;
