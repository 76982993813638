const templateData = [
  {
    id: "Accidentalleave",
    title: "Accidental Leave",
    content:
      "Dear {#var#},\r\nThere will be a holiday on account of {#var#} on {#var#}.\r\nPowered by Pragmatic S/W Sol.",
    example:
      "Dear Parents,\r\nThere will be a holiday on account of Excessive Rainfall on 22-Jun-2023.\r\nPowered by Pragmatic S/W Sol.",
  },
  {
    id: "FeesReminder",
    title: "Fees Reminder",
    content:
      "Dear {#var#},\r\nPlease submit the fees of your {#var#} before Due Date {#var#}.\r\nPowered by Pragmatic S/W Sol.",
    example:
      "Dear Parents,\r\nPlease submit the fees of your child before Due Date 22-Jun-2023.\r\nPowered by Pragmatic S/W Sol.",
  },
  {
    id: "HolidayannouncementsNotification",
    title: "Holiday Announcements Notification",
    content:
      "Dear {#var#},\r\nWishes you a happy {#var#}.\r\nPowered by Pragmatic S/W Sol.",
    example:
      "Dear Parents, \r\nWishes you a happy Diwali. \r\nPowered by Pragmatic S/W Sol.",
  },
];

export default templateData;
