import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/locale-en.json";
import translationHI from "./locales/hi/locale-hi.json";

const resources = {
  en: {
    translation: translationEN,
  },
  hi: {
    translation: translationHI,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  lng: localStorage.language || "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
