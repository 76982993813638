import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardManualImage from "../../data/dashboardManualImages";

const DashboardWidget = ({ size = "60px" }) => {
  const navigate = useNavigate();
  const BoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.grey[200],
    borderRadius: "10px",
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: "20px",
    width: "12%",
    margin: "1%",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[100],
    },
    "&:active": {
      backgroundColor: theme.palette.grey[300],
      border: `1px solid ${theme.palette.grey[600]}`,
    },
    "& img": {
      marginBottom: 15,
    },
    "& p": {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width: "48%",
    },
  }));
  return (
    <>
      {DashboardManualImage.map((item, idx) => (
        <BoxContainer
          key={idx}
          onClick={() =>
            navigate(`/Dashboard/send_manual?type=${item?.linkTo}`)
          }
        >
          <img width={size} height={size} alt={item?.alt} src={item?.src} />
          <Typography fontSize="0.8rem">{item?.Name}</Typography>
        </BoxContainer>
      ))}
    </>
  );
};

export default DashboardWidget;
