import React, { useState } from "react";

import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  styled,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
// import dayjs, { Dayjs } from "dayjs";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";

import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { authAPI } from "../../axios/authAPI";

const SmsButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  "& > div": {
    paddingLeft: 0,
    marginRight: 15,
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    "& > div": {
      width: "100% !important",
    },
    "& > button": {
      width: "100% !important",
    },
  },
}));

const SmsReport = () => {
  const theme = useTheme();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // const [value, setValue] = React.useState(null);
  const [smsReportData, getSmsReportData] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNonMobileScreens = useMediaQuery("(min-width:991px)");

  const dataSelection = ["all", "in", "out", "absent"];

  // useEffect(() => {
  //   const getStudentData = async () => {
  //     const checkData = await authAPI.get(
  //       `/api/sms-reports?userid=${userDetails?.id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  //         },
  //       }
  //     );

  //     const newResponseData = await checkData?.data;

  //     getSmsReportData(newResponseData);
  //   };

  //   getStudentData();
  // }, []);

  const initialValues = {
    From: "",
    To: "",
    Timeline: dataSelection[0],
  };

  const userSchema = yup.object({
    From: yup.date().nullable(),
    To: yup.date().nullable(),

    // Class: yup.string().oneOf(classList, "Please select the class"),
    // Sections: yup.string().oneOf(sectonList, "Please select the section"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: (values) => {
      authAPI
        .get(
          `/api/sms-reports?userid=${userDetails?.id}&fromDate=${values?.From}&toDate=${values?.To}&type=${values?.Timeline}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {
          const check = res?.data.map((item, idx) => ({
            ...item,
            Sr_ID: idx + 1,
          }));
          getSmsReportData(check);
        })
        .catch((err) => console.log(err));
    },
  });

  const columns = [
    {
      field: "Sr_ID",
      headerName: "Sr",
    },
    {
      field: "Sms_Text",
      headerName: "SMS Text",
      headerAlign: "left",
      align: "left",
      flexWrap: "wrap",
      // flex: 0.4,
      width: isNonMobileScreens ? 1000 : 500,
    },
    {
      field: "time",
      headerName: "Time",
      // flex: 0.1,
      width: isNonMobileScreens ? 250 : 200,
      cellClassName: "name-column-cell",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {moment
              .utc(params.row?.time)
              .local()
              .format("MMMM Do YYYY, h:mm:ss a")}
          </Box>
        );
      },
    },
    {
      field: "mobile_no",
      headerName: "Mobile No.",
      // flex: 0.1,
      width: 200,
    },
  ];
  return (
    <Box m="0 20px">
      <Box>
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          SMS Report
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography> */}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <SmsButtonContainer>
          <label htmlFor="From">
            <Box display="flex" flexDirection={isNonMobile ? "row" : "column"}>
              <p style={{ marginTop: isNonMobile ? "1.5rem" : "0rem" }}>From</p>
              <input
                type="date"
                id="From"
                name="From"
                onChange={formik.handleChange}
                value={formik.values.From}
                style={{
                  margin: isNonMobile ? "0.5rem 1rem" : "0.5rem 0rem",
                  padding: "1rem 0.3rem",
                  border: "0.5px solid lightgray",
                  borderRadius: "5%",
                  backgroundColor: "#f9fafb",
                }}
              />
            </Box>
          </label>
          <label htmlFor="To">
            <Box display="flex" flexDirection={isNonMobile ? "row" : "column"}>
              <p style={{ marginTop: isNonMobile ? "1.5rem" : "0rem" }}>To</p>
              <input
                type="date"
                id="To"
                name="To"
                onChange={formik.handleChange}
                value={formik.values.To}
                style={{
                  margin: isNonMobile ? "0.5rem 1rem" : "0.5rem 0rem",
                  padding: "1rem 0.3rem",
                  border: "0.5px solid lightgray",
                  borderRadius: "5%",
                  backgroundColor: "#f9fafb",
                }}
              />
            </Box>
          </label>
          <FormControl sx={{ width: "10rem", marginTop: "0.5rem" }}>
            <InputLabel id="demo-simple-select-label">Timeline</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="Timeline"
              name="Timeline"
              value={formik.values.Timeline}
              label="Timeline"
              onChange={formik.handleChange}
              error={formik.touched.Timeline && Boolean(formik.errors.Timeline)}
              helperText={formik.touched.Timeline && formik.errors.Timeline}
            >
              {dataSelection.map((c) => (
                <MenuItem value={c} key={c}>
                  {c}
                </MenuItem>
              ))}

              {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{ width: "7rem", height: "3rem", marginTop: "0.5rem" }}
            type="submit"
          >
            Submit
          </Button>
        </SmsButtonContainer>
      </form>
      <Box
        m="40px 0 0 0"
        height="58vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            border: 1,
            borderColor: `${theme.palette.grey[400]}`,
            padding: "1rem 0.5rem !important",
          },
          "& .name-column--cell": {
            // color: "red",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${theme.palette.grey[300]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: `${theme.palette.grey[200]}`,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: `${theme.palette.grey[300]}`,
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.grey[700]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.grey[700]} !important`,
          },
        }}
      >
        <DataGrid
          rows={smsReportData}
          columns={columns}
          getRowHeight={() => "auto"}
          // checkboxSelection
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          disableColumnFilter
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default SmsReport;
