import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Typography,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import { styled, alpha } from "@mui/material/styles";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import Alert from "@mui/material/Alert";
import { authAPI } from "../../axios/authAPI";

const ModalAddSection = ({ openModal, handleClose, flag, setFlag }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isNonMobile ? 500 : 350,
    height: 250,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [sectionName, setSectionName] = useState("");
  const [err, setErr] = useState("");

  const handleSubmit = async () => {
    authAPI
      .post(
        `/api/sections`,
        {
          data: {
            sectionName,
            userID: userDetails?.id,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        setFlag(!flag);
        handleClose(false);
      })
      .catch((err) => {
        setErr("Please try again later");
      });
  };
  return (
    <Box m="20px">
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ m: "0 0 5px 0" }}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              Add Section
            </Typography>
          </Box>
          <Box>
            <TextField
              fullWidth
              id="class_name"
              name="class_name"
              label="Class Name"
              value={sectionName}
              onChange={(e) => setSectionName(e.target.value)}
              autoFocus
              sx={{ margin: "20px 0 0 0" }}
              inputProps={{ pattern: "[0-9]{1,15}" }}
            />
            <p style={{ color: "red", display: "block" }}>{err}</p>
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
              sx={{ marginTop: "20px" }}
              disabled={!sectionName.length > 0}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ModalAddSection;
