import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Typography,
  FormControl,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import SchoolIcon from "@mui/icons-material/School";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import Papa from "papaparse";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { authAPI } from "../../axios/authAPI";
import StatusField from "../statusField/StatusField";
import DataDisplayModal from "./DataDisplayModal";

const AddStudent = () => {
  const [classInfo, setClass] = useState("");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [openStatus, setOpenStatus] = useState(false);
  const [statusType, setStatusType] = useState();
  const [statusText, setStatusText] = useState();
  const [classList, setClassList] = useState(["I"]);
  const [sectonList, setSectionList] = useState(["A"]);
  const [csvData, setCsvData] = React.useState([]);
  const [addData, setAddData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openDataModal, setOpenDataModal] = useState(false);
  const [selectedAddData, setSelectedAddData] = useState([]);

  const inputRef = useRef();

  const handleJsonData = (jsonData) => {
    const keys = jsonData[0];

    const values = jsonData;

    const newArr = values.map((item) =>
      item.length > 2
        ? Object.assign(...keys.map((k, i) => ({ [k]: item[i] })))
        : ""
    );
    newArr.shift();
    setAddData(newArr);

    const check = newArr.map((x) => ({
      ...x,
      status: true,
      Contact_No: parseInt(x.Contact_No, 10),
      userID: userDetails?.id,
    }));
    check.pop();
    setSelectedAddData(check);
    setOpenDataModal(true);
    // const promiseArray = [];
    // for (let i = 0; i < check.length; i++) {
    //   promiseArray.push(
    //     authAPI.post(
    //       `/api/add-students`,
    //       { data: check[i] },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    //         },
    //       }
    //     )
    //   );
    // }
    // Promise.all(promiseArray)
    //   .then((values) => values.map((val) => console.log(val)))
    //   .catch((err) => console.log(err));
  };
  const handleCloseModal = () => {
    setOpenDataModal(false);
    setCsvData([]);
    inputRef.current.value = null;
  };
  const initialValues = {
    Name: "",
    email: "",
    RFID_No: "",
    IcardNo: "",
    Contact_No: "",
    Admission_No: "",
    Alt_No: "",

    Sections: sectonList[0],
    Class: classList[0],
    status: true,
  };

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const userSchema = yup.object({
    Name: yup.string().required("required"),
    email: yup.string().email("invalid email"),
    RFID_No: yup.string().required("required"),
    IcardNo: yup.string().required("required"),
    Contact_No: yup.number().required("required"),
    Admission_No: yup.string().required("required"),
    Alt_No: yup.number(),
    // Class: yup.string().oneOf(classList, "Please select the class"),
    // Sections: yup.string().oneOf(sectonList, "Please select the section"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values, { resetForm }) => {
      values.Contact_No = parseInt(values.Contact_No, 10);
      values.Alt_No = parseInt(values.Alt_No, 10);
      // values.schoolId = parseInt(userDetails?.id, 10);
      // values.school = parseInt(userDetails?.id, 10);
      values.userID = userDetails?.id;

      authAPI
        .post(
          `/api/add-students`,
          { data: values },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {
          setStatusText("Student has been added successfully");
          setStatusType("success");
          setOpenStatus(true);
          resetForm();
          setTimeout(() => {
            setOpenStatus(false);
          }, 2500);
        })
        .catch((err) => {
          // console.log();

          // const errRes = err?.response?.data?.error?.details?.errors;
          // const stringErr = "";
          // const checkErr = errRes.map((x) => console.log(x?.path));

          setStatusText(
            `${err?.response?.data?.error?.details?.errors[0].path[0]} must be unique`
          );
          setStatusType("warning");
          setOpenStatus(true);
        });
    },
  });
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const InputContainerStyle = styled(Box)(({ theme }) => ({
    display: "grid",
    gap: "30px",
    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
    "@media (max-width: 767px)": {
      display: "flex",
      flexDirection: "column",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      "& > div": {
        width: "47%",
      },
    },
  }));

  useEffect(() => {
    const fetchClassData = async () => {
      const fetchdata = await authAPI.get(
        `/api/classes?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const responseData = await fetchdata?.data;

      setClassList(responseData);
    };
    fetchClassData();
  }, []);

  useEffect(() => {
    const fetchSectionData = async () => {
      const fetchdata = await authAPI.get(
        `/api/sections?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      const responseData = await fetchdata?.data;

      setSectionList(responseData);
    };
    fetchSectionData();
  }, []);

  return (
    <Box m="20px">
      <Box mb="30px" display="flex" justifyContent="space-between">
        <Box>
          <Typography
            variant="h4"
            color={theme.palette.grey[600]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <SchoolIcon
              sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
            />
            Add student
          </Typography>
        </Box>
        <Box>
          <Box display="flex">
            <Button
              component="label"
              variant="contained"
              // sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
            >
              Upload
              <input
                hidden
                type="file"
                accept=".csv"
                ref={inputRef}
                onChange={(e) => {
                  const files = e.target.files;
                  setCsvData([]);
                  console.log(files[0].type);
                  if (files[0].type !== "text/csv")
                    return alert(
                      "only csv files are allowed to import data :("
                    );
                  if (files[0].type === "text/csv") {
                    Papa.parse(files[0], {
                      complete: (results) => {
                        setCsvData(results.data);
                      },
                    });
                  }
                }}
              />
            </Button>
            {loading ? (
              <LoadingButton
                loading
                loadingIndicator="Loading…"
                variant="outlined"
                sx={{ marginLeft: "1rem" }}
              >
                Fetch data
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                disabled={csvData.length === 0}
                onClick={() => handleJsonData(csvData)}
                sx={{ marginLeft: "1rem" }}
              >
                Add Data
              </Button>
            )}
          </Box>
          <Typography color={theme.palette.error.main} sx={{ m: "0 0 5px 0" }}>
            Only csv files are allowed to upload
          </Typography>
        </Box>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        {/* <InputContainerStyle> */}
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4 , minmax(0 , 1fr))"
          sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
        >
          <TextField
            fullWidth
            id="Name"
            name="Name"
            label="Name"
            value={formik.values.Name}
            onChange={formik.handleChange}
            error={formik.touched.Name && Boolean(formik.errors.Name)}
            // helperText={formik.touched.name && formik.errors.name}
            sx={{ gridColumn: "span 2" }}
            autoComplete="off"
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{ gridColumn: "span 2" }}
            autoComplete="off"
          />

          <TextField
            fullWidth
            id="RFID_No"
            name="RFID_No"
            label="RFID_NO"
            value={formik.values.RFID_No}
            onChange={formik.handleChange}
            error={formik.touched.RFID_No && Boolean(formik.errors.RFID_No)}
            helperText={formik.touched.RFID_No && formik.errors.RFID_No}
            sx={{ gridColumn: "span 2" }}
            autoComplete="off"
          />
          <TextField
            fullWidth
            id="IcardNo"
            name="IcardNo"
            label="Icard_No"
            value={formik.values.IcardNo}
            onChange={formik.handleChange}
            error={formik.touched.IcardNo && Boolean(formik.errors.IcardNo)}
            helperText={formik.touched.IcardNo && formik.errors.IcardNo}
            sx={{ gridColumn: "span 2" }}
            autoComplete="off"
          />

          <TextField
            fullWidth
            id="Contact_No"
            name="Contact_No"
            label="Contact No"
            value={formik.values.Contact_No}
            onChange={formik.handleChange}
            error={
              formik.touched.Contact_No && Boolean(formik.errors.Contact_No)
            }
            helperText={formik.touched.Contact_No && formik.errors.Contact_No}
            sx={{ gridColumn: "span 2" }}
            autoComplete="off"
          />

          <TextField
            fullWidth
            id="Admission_No"
            name="Admission_No"
            label="Admission No"
            value={formik.values.Admission_No}
            onChange={formik.handleChange}
            error={
              formik.touched.Admission_No && Boolean(formik.errors.Admission_No)
            }
            helperText={
              formik.touched.Admission_No && formik.errors.Admission_No
            }
            sx={{ gridColumn: "span 2" }}
            autoComplete="off"
          />

          <TextField
            fullWidth
            id="Alt_No"
            name="Alt_No"
            label="Alt no"
            value={formik.values.Alt_No}
            onChange={formik.handleChange}
            error={formik.touched.Alt_No && Boolean(formik.errors.Alt_No)}
            helperText={formik.touched.Alt_No && formik.errors.Alt_No}
            sx={{ gridColumn: "span 2" }}
            autoComplete="off"
          />
          <Box
            sx={{
              gridColumn: "span 2",
            }}
          >
            <FormControl sx={{ width: "5rem", marginRight: "2rem" }}>
              <InputLabel id="demo-simple-select-label">Class</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Class"
                name="Class"
                value={formik.values.Class}
                label="Class"
                onChange={formik.handleChange}
                error={formik.touched.Class && Boolean(formik.errors.Class)}
                helperText={formik.touched.Class && formik.errors.Class}
              >
                {classList.map((c) => (
                  <MenuItem value={c?.ClassName} key={c?.id}>
                    {c?.ClassName}
                  </MenuItem>
                ))}

                {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "5rem", marginRight: "2rem" }}>
              <InputLabel id="demo-simple-select-label">Sections</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Sections"
                name="Sections"
                value={formik.values.Sections}
                label="Sections"
                onChange={formik.handleChange}
                error={
                  formik.touched.Sections && Boolean(formik.errors.Sections)
                }
                helperText={formik.touched.Sections && formik.errors.Sections}
              >
                {sectonList.map((c) => (
                  <MenuItem value={c?.sectionName} key={c?.id}>
                    {c?.sectionName}
                  </MenuItem>
                ))}

                {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
              </Select>
            </FormControl>
          </Box>

          {/* <TextField
            fullWidth
            id="alt_no"
            name="alt_no"
            label="Alt no"
            value={formik.values.alt_no}
            onChange={formik.handleChange}
            error={formik.touched.alt_no && Boolean(formik.errors.alt_no)}
            helperText={formik.touched.alt_no && formik.errors.alt_no}
            autoFocus
            sx={{ gridColumn: "span 2" }}
          /> */}

          {/* <Box display="flex" justifyContent="end" mt="20px"> */}
          <Button
            type="submit"
            variant="contained"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            Create User
          </Button>
          {/* </Box> */}
        </Box>
        {/* </InputContainerStyle> */}
      </form>

      {openStatus && (
        <StatusField
          setOpenStatus={setOpenStatus}
          statusType={statusType}
          statusText={statusText}
        />
      )}
      {openDataModal && (
        <Box>
          <DataDisplayModal
            setOpenDataModal={handleCloseModal}
            openDataModal={openDataModal}
            selectedAddData={selectedAddData}
          />
        </Box>
      )}

      {/* </Formik> */}
    </Box>
  );
};

export default AddStudent;
