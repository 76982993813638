import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import Modal from "@mui/material/Modal";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { authAPI } from "../../axios/authAPI";
import SystemModal from "./SystemModal";

const System = () => {
  const theme = useTheme();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [open, setOpen] = React.useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const handleClose = () => setOpen(false);

  const [systemData, setSystemData] = useState([]);

  const handleSelectedData = (data) => {
    setSelectedData(data);
    setOpen(true);
  };

  useEffect(() => {
    const fetchSystemData = async () => {
      const testData = await authAPI.get(
        `api/machine-details?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const responseData = await testData?.data;
      responseData.sort(
        (a, b) => new Date(b.LastMessage) - new Date(a.LastMessage)
      );
      const check = responseData.map((item, idx) => ({
        ...item,
        Sr_id: idx + 1,
      }));

      setSystemData(check);
    };

    fetchSystemData();
  }, []);

  const handleLiveData = async () => {};

  const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    {
      field: "Sr_id",
      headerName: "Sr",
      headerAlign: "left",
      align: "left",
      width: 130,
    },
    {
      field: "Sim_no",
      headerName: "Sim No.",
      headerAlign: "left",
      align: "left",
      width: 300,
      // flex: 0.2,
    },
    {
      field: "IMEI_No",
      headerName: "IMEI No",
      // flex: 0.2,
      width: 450,
      cellClassName: "name-column-cell",
    },
    // {
    //   field: "Last_String",
    //   headerName: "Last String",
    //   flex: 0.2,
    // },
    {
      field: "LastMessage",
      headerName: "Last Message",
      // flex: 0.4,
      width: 450,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {moment
              .utc(params.row?.LastMessage)
              .local()
              .format("MMMM Do YYYY, h:mm:ss a")}
          </Box>
        );
      },
    },
    // {
    //   field: "Location",
    //   headerName: "Location",
    //   flex: 0.2,
    // },
    // {
    //   field: "Today_Hit",
    //   headerName: "Today's Hit",
    //   flex: 0.2,
    // },
    // {
    //   field: "Hit_Percentage",
    //   headerName: "Hit%",
    //   flex: 0.2,
    // },
    {
      field: "liveFunc",
      headerName: "Live",
      // flex: 0.2,
      width: 250,
      renderCell: (params) => {
        return (
          <Box onClick={() => handleSelectedData(params.row)}>
            <Typography
              sx={{
                color: `${theme.palette.primary.light}`,
                cursor: "pointer",
              }}
              variant="h6"
            >
              Live
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box mb="30px">
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          Device Manage
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography> */}
      </Box>
      <Box
        m="40px 0 0 0"
        height="65vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            border: 1,
            borderColor: `${theme.palette.grey[400]} !important`,
            padding: "1rem 0.5rem !important",
          },
          "& .name-column--cell": {
            // color: "red",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${theme.palette.grey[300]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: `${theme.palette.grey[200]}`,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: `${theme.palette.grey[300]}`,
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.grey[700]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.grey[700]} !important`,
          },
        }}
      >
        <DataGrid
          rows={systemData}
          columns={columns}
          getRowHeight={() => "auto"}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          disableColumnFilter
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Device Manage
            </Typography>
            <Box
              m="40px 0 0 0"
              height="60vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  // color: "red",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: `${theme.palette.grey[300]}`,
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: `${theme.palette.grey[200]}`,
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: `${theme.palette.grey[300]}`,
                },
                "& .MuiCheckbox-root": {
                  color: `${theme.palette.grey[700]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${theme.palette.grey[700]} !important`,
                },
              }}
            >
              <DataGrid
                rows={liveMockData}
                columns={liveColumns}
                disableRowSelectionOnClick
                slots={{
                  toolbar: GridToolbar,
                }}
                disableColumnFilter
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </Box>
        </Modal> */}

        {open && (
          <Box>
            <SystemModal
              open={open}
              handleClose={() => setOpen(false)}
              selectedData={selectedData}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default System;
