import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, TextField } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import Modal from "@mui/material/Modal";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { authAPI } from "../../axios/authAPI";

const SystemModal = ({ open, handleClose, selectedData }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showLiveData, setShowLiveData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [flagShowTable, setFlagShowTable] = useState(true);
  const [loader, setLoader] = useState(true);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isNonMobile ? "80%" : 350,
    height: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // overflowY: "scroll",
  };
  const theme = useTheme();

  useEffect(() => {
    const liveSystemData = async () => {
      try {
        const fetchData = await authAPI.get(
          `api/systems-details?imei=${selectedData?.IMEI_No}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        const responseData = await fetchData?.data;
        const newCheckData = responseData.map((x) => ({
          ...x,
          newTime: moment
            .utc(x?.Time)
            .local()
            .format("MMMM Do YYYY, h:mm:ss a"),
        }));
        newCheckData.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
        setShowLiveData(newCheckData);
      } catch (error) {
        console.log(error);
      }
    };

    liveSystemData();

    const interval = setInterval(() => {
      liveSystemData();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [search, setSearch] = useState();
  const requestSearch = (searchedVal) => {
    setLoader(true);
    setSearch(searchedVal);

    const filteredRows = showLiveData.filter((row) => {
      return (
        (row.StudentName !== null &&
          row.StudentName.toLowerCase().includes(searchedVal.toLowerCase())) ||
        row.RFID_No.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.newTime.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });

    setFilteredData(filteredRows);
    setFlagShowTable(searchedVal.length === 0);

    setTimeout(() => {
      setLoader(false);
    }, 3000);
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection="row"
            alignContent="center"
            justifyContent="space-between"
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ margin: "1rem 0 0 0" }}
            >
              Live Data Manage
            </Typography>
            <TextField
              id="filled-basic"
              label="Search Entries"
              variant="filled"
              value={search}
              onChange={(e) => requestSearch(e.target.value)}
              sx={{ margin: "0 0 1rem 0" }}
            />
          </Box>

          {/* <Box
            m="40px 0 0 0"
            height="60vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                // color: "red",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: `${theme.palette.grey[300]}`,
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: `${theme.palette.grey[200]}`,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: `${theme.palette.grey[300]}`,
              },
              "& .MuiCheckbox-root": {
                color: `${theme.palette.grey[700]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.grey[700]} !important`,
              },
            }}
          >
            <DataGrid
              rows={showLiveData}
              columns={liveColumns}
              disableRowSelectionOnClick
              slots={{
                toolbar: GridToolbar,
              }}
              disableColumnFilter
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box> */}
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {flagShowTable ? (
              <>
                <TableContainer sx={{ maxHeight: 480 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">Sr</StyledTableCell>
                        <StyledTableCell align="left">RFID</StyledTableCell>
                        <StyledTableCell align="left">Name</StyledTableCell>
                        <StyledTableCell align="left">Time</StyledTableCell>
                        <StyledTableCell align="left">
                          Heartbeat
                        </StyledTableCell>
                        {/* <TableCell>Difference</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ overflowY: "scroll" }}>
                      {showLiveData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <StyledTableRow key={row?.id}>
                            <StyledTableCell component="th" scope="row">
                              {row?.id}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.RFID_No}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.StudentName === null
                                ? "Student Name"
                                : row?.StudentName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.newTime}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.heartbeat === null
                                ? "Heartbeat"
                                : row?.heartbeat}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    25,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  component="div"
                  count={showLiveData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <TableContainer sx={{ maxHeight: 480 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Sr</StyledTableCell>
                      <StyledTableCell align="left">RFID</StyledTableCell>
                      <StyledTableCell align="left">Name</StyledTableCell>
                      <StyledTableCell align="left">Time</StyledTableCell>
                      <StyledTableCell align="left">Heartbeat</StyledTableCell>
                      {/* <TableCell>Difference</TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ overflowY: "scroll" }}>
                    {loader ? (
                      <div>
                        <p>loading....</p>
                      </div>
                    ) : (
                      filteredData.map((row) => {
                        return (
                          <StyledTableRow key={row?.id}>
                            <StyledTableCell component="th" scope="row">
                              {row?.id}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.RFID_No}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.StudentName === null
                                ? "Student Name"
                                : row?.StudentName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.newTime}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.heartbeat === null
                                ? "Heartbeat"
                                : row?.heartbeat}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

export default SystemModal;
