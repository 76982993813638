import AccidentalLeave from "../assets/png/accidental_leave.png";
// import HomeWorks from "../assets/png/home_works.png";
// import StudentAdmission from "../assets/png/student_admission.png";
// import Timetable from "../assets/png/timetable.png";
// import Planning from "../assets/png/planning.png";
// import ProgramManagement from "../assets/png/program_management.png";
// import CoursePlanning from "../assets/png/course_planning.png";
import FeesReminder from "../assets/png/fees_reminder.png";
// import NoticeBoard from "../assets/png/notice_board.png";
// import Bus from "../assets/png/bus.png";
// import ExamsTest from "../assets/png/exams_test.png";
import Holiday from "../assets/png/holiday.png";
// import FeeDetails from "../assets/png/feeDetails.png";
// import FeesAcknowledgement from "../assets/png/fees_acknowledgement.png";
// import Competiton from "../assets/png/competiton.png";
// import AcademicSchedule from "../assets/png/academicSchedule.png";
// import Achievement from "../assets/png/achievement.png";
// import ChildrenMarks from "../assets/png/childrenMarks.png";
// import LatestAnnouncement from "../assets/png/latestAnnouncement.png";
// import ImportantDate from "../assets/png/importantDate.png";
// import Appointment from "../assets/png/appointment.png";
import Discipline from "../assets/png/discipline.png";
// import Meetings from "../assets/png/meetings.png";

const DashboardManualImage = [
  {
    Name: "Accidental Leave",
    alt: "accidental_leave",
    src: AccidentalLeave,
    linkTo: "Accidentalleave",
  },

  {
    Name: "Fees Reminder",
    alt: "fees_reminder",
    src: FeesReminder,
    linkTo: "FeesReminder",
  },
  // {
  //   Name: "Virtual Notice Board",
  //   alt: "notice_board",
  //   src: NoticeBoard,
  //   linkTo: "VirtualNoticeBoard",
  // },
  // {
  //   Name: "Meetings",
  //   alt: "meetings",
  //   src: Meetings,
  //   linkTo: "MeetingsandimportantdatesNotification",
  // },
  // {
  //   Name: "Bus Notification",
  //   alt: "bus",
  //   src: Bus,
  //   linkTo: "SchoolBusNotification",
  // },
  // {
  //   Name: "Exams / Test Result",
  //   alt: "ExamsTest",
  //   src: ExamsTest,
  //   linkTo: "ExamTestresultsNotification",
  // },
  {
    Name: "Holidays Announcement",
    alt: "holidays",
    src: Holiday,
    linkTo: "HolidayannouncementsNotification",
  },
  // {
  //   Name: "Fee Details",
  //   alt: "fee_details",
  //   src: FeeDetails,
  //   linkTo: "FeedetailsNotification",
  // },
  // {
  //   Name: "Fees Acknowledgement",
  //   alt: "fees_acknowledgement",
  //   src: FeesAcknowledgement,
  //   linkTo: "Feeacknowledgement",
  // },
  // {
  //   Name: "Dates of Competiton",
  //   alt: "competiton",
  //   src: Competiton,
  //   linkTo: "Datesofcompetitions",
  // },
  // {
  //   Name: "Academic Schedule",
  //   alt: "academicSchedule",
  //   src: AcademicSchedule,
  //   linkTo: "Academicschedule",
  // },
  // {
  //   Name: "Achievement of Schools",
  //   alt: "achievement",
  //   src: Achievement,
  //   linkTo: "Achievementsoftheschool",
  // },
  // {
  //   Name: "Children's Marks",
  //   alt: "ChildrenMarks",
  //   src: ChildrenMarks,
  //   linkTo: "childrenMarks",
  // },
  // {
  //   Name: "Latest Announcement",
  //   alt: "LatestAnnouncement",
  //   src: LatestAnnouncement,
  //   linkTo: "latestannouncements",
  // },
  // {
  //   Name: "Important Date of Meetings",
  //   alt: "importantDate",
  //   src: ImportantDate,
  //   linkTo: "importantdatesofmeetings",
  // },
  // {
  //   Name: "Appointment with Teachers",
  //   alt: "appointment",
  //   src: Appointment,
  //   linkTo: "appointmentwiththeteacher",
  // },
  {
    Name: "Uniform Discipline",
    alt: "discipline",
    src: Discipline,
    linkTo: "",
  },
];

export default DashboardManualImage;
