import React, { useState, useEffect, createContext } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

export const CContext = createContext();

const defaultLang = localStorage.language || 'en';

const CContextProvider = ({ children }) => {
  const [lang, setLang] = useState(defaultLang);

  const changeLanguage = () => {
    const language = lang === 'en' ? 'hi' : 'en';
    setLang(language);
  };

  const contextValue = {
    lang,
    setLang,
    changeLanguage
  };

  useEffect(() => {
    i18next.changeLanguage(lang);
    localStorage.language = lang;
  }, [lang]);

  return <CContext.Provider value={contextValue}>{children}</CContext.Provider>;
};

CContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CContextProvider;
