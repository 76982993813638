import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Typography,
  FormControl,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import { authAPI } from "../../axios/authAPI";

const SMSTemplateModal = ({ open, handleClose }) => {
  //   const [open, setOpen] = useState(false);

  //   const handleClose = () => setOpen(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const tag = ["Student Name", "Date", "Time", "School Name"];
  const category = [
    "All",
    "Accidentalleave",
    "Homeworks",
    "Time Table",
    "Meetings",
    "Student Admission Notification",
    "Syllabus planning Notification",
    "Programs Management",
    "Course Planning",
    "Fees Reminder",
    "Virtual Notice Board",
    "Bus Notification",
    "Exam / Test results",
    "Holiday announcements",
    "Fee details",
    "Fee acknowledgment",
    "Dates of competitions",
    "Academic schedule",
    "Achievements of the school",
    "Children's marks",
    "Latest announcements",
    "Important dates of meetings",
    "Appointment with the teacher",
    "Discipline Related",
  ];

  const initialValues = {
    Template_type: "",
    Tag: tag[0],
    Category: category[0],
    SMS_Format: "",
    status: true,
    userID: userDetails?.id,
  };

  const userSchema = yup.object({
    Template_type: yup.string().required("required"),
    SMS_Format: yup.string().required("required"),
    Category: yup.string().oneOf(category, "Please select the class"),
    Tag: yup.string().oneOf(tag, "Please select the section"),
  });

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      authAPI
        .post(`/api/sms-templates`, { data: values })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isNonMobile ? 500 : 350,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Template
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box
              marginTop="2rem"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <TextField
                // fullWidth
                id="Template_type"
                name="Template_type"
                label="Name"
                value={formik.values.Template_type}
                onChange={formik.handleChange}
                error={
                  formik.touched.Template_type &&
                  Boolean(formik.errors.Template_type)
                }
                helperText={
                  formik.touched.Template_type && formik.errors.Template_type
                }
                sx={{ width: "100%", marginBottom: '20px'  }}
              />
              <FormControl sx={{ width: "100%", marginBottom: '20px' }}>
                <InputLabel id="demo-simple-select-label">Tag</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="Tag"
                  name="Tag"
                  value={formik.values.Tag}
                  label="Tag"
                  onChange={formik.handleChange}
                  error={formik.touched.Tag && Boolean(formik.errors.Tag)}
                  helperText={formik.touched.Tag && formik.errors.Tag}
                >
                  {tag.map((el, i) => (
                    <MenuItem value={el} key={i}>
                      {el}
                    </MenuItem>
                  ))}

                  {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
              <TextField
                // fullWidth
                id="SMS_Format"
                name="SMS_Format"
                label="Text"
                multiline
                rows={2}
                value={formik.values.SMS_Format}
                onChange={formik.handleChange}
                error={
                  formik.touched.SMS_Format && Boolean(formik.errors.SMS_Format)
                }
                helperText={
                  formik.touched.SMS_Format && formik.errors.SMS_Format
                }
                // autoFocus
                sx={{ width: "100%", marginBottom: '20px' }}
              />
              <FormControl sx={{width: "100%", marginBottom: '20px'}}>
                <InputLabel id="demo-simple-select-label">Class</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="Category"
                  name="Category"
                  value={formik.values.Category}
                  label="Category"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.Category && Boolean(formik.errors.Category)
                  }
                  helperText={formik.touched.Category && formik.errors.Category}
                >
                  {category.map((x, i) => (
                    <MenuItem value={x} key={i}>
                      {x}
                    </MenuItem>
                  ))}

                  {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <Button type="submit" variant="contained" sx={{ width: "2rem" }}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default SMSTemplateModal;
