import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Typography,
  FormControl,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";

import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import GIFSuccess from "../../assets/gif/tick-success.gif";
import { authAPI } from "../../axios/authAPI";

const ModalDeleteStudent = ({
  openDeleteModal,
  handleClose,
  details,
  flag,
  setFlag,
  deleteID,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isNonMobile ? 550 : 350,
    height: 240,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [loader, setLoader] = useState(false);
  const [loaderSuccess, setLoaderSuccess] = useState(false);
  const [err, setErr] = useState("");

  const handleDeleteModal = async () => {
    setLoader(true);
    authAPI
      .delete(`/api/add-students/${deleteID?.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((res) => {
        setFlag(!flag);
        setLoaderSuccess(true);
        setTimeout(() => {
          setLoaderSuccess(false);
          handleClose(false);
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        setErr("Please try again later");
      });
  };

  return (
    <Box m="20px">
      <Modal
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loaderSuccess ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={GIFSuccess} alt="gif" width={150} height={150} />
            </Box>
          ) : (
            <Box>
              <Box>
                <Typography
                  variant="h4"
                  fontWeight={200}
                  sx={{ m: "0 0 5px 0" }}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  color="red"
                >
                  Are you sure?
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={100}
                  sx={{ m: "1rem 0 1rem 1rem" }}
                >
                  You are going to delete{" "}
                  <span style={{ fontWeight: "bold" }}> {deleteID?.Name} </span>{" "}
                  from database !!!
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="2rem"
              >
                {loader ? (
                  <LoadingButton
                    loading
                    variant="outlined"
                    sx={{ marginRight: "2rem" }}
                  >
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    sx={{ marginRight: "2rem" }}
                    color="error"
                    onClick={handleDeleteModal}
                  >
                    Delete
                  </Button>
                )}
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <p>{err}</p>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default ModalDeleteStudent;
