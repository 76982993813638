import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  styled,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import * as yup from "yup";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { authAPI } from "../../axios/authAPI";
import StatusField from "../statusField/StatusField";

const StudentDaywise = () => {
  const theme = useTheme();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [value, setValue] = useState(null);
  const [openStatus, setOpenStatus] = useState(false);
  const [statusInfo, setStatusInfo] = useState({
    statusText: "",
    statusType: "",
  });
  const [classList, setClassList] = useState(["All"]);
  const [sectonList, setSectionList] = useState(["All"]);
  const [showData, setShowData] = useState([]);
  const [btnClicked, setBtnClicked] = useState("");
  const [textColor, setTextColor] = useState("yellow");
  const columns = [
    {
      field: "id",
      headerName: "Sr",
    },

    {
      field: "Name",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      width: 200,
    },

    {
      field: "Admission_No",
      headerName: "Admission No",
      width: 250,
      cellClassName: "name-column-cell",
    },

    {
      field: "IcardNo",
      headerName: "Icard No",
      width: 250,
    },

    {
      field: "Class",
      headerName: "Class",
      width: 150,
    },
    {
      field: "Sections",
      headerName: "Sections",
      width: 150,
    },

    {
      field: "Contact_No",
      headerName: "Contact Number",
      width: 200,
      renderCell: (params) => {
        return (
          <Box>
            {params.row.Contact_No === null ? (
              <p style={{ color: textColor }}>Contact Number</p>
            ) : (
              <p style={{ color: textColor }}>{params.row.Contact_No}</p>
            )}
          </Box>
        );
      },
    },

    {
      field: "Attendance",
      headerName: "Attendance",
      width: 80,
      renderCell: (params) => {
        if (params.row.Attendance !== true) {
          return (
            <Box>
              <Typography
                sx={{
                  color: "white",
                  padding: "0 6px",
                  borderRadius: "4px",
                  backgroundColor: `${theme.palette.error.main}`,
                }}
              >
                A
              </Typography>
            </Box>
          );
        }

        return (
          <Box>
            <Typography
              sx={{
                color: "white",
                padding: "0 6px",
                borderRadius: "4px",
                backgroundColor: `${theme.palette.success.dark}`,
              }}
            >
              P
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "Time",
      headerName: "Time",
      width: 250,
      // renderCell: (params) => {
      //   return (
      //     <Box>
      //       {params.row?.Time === undefined ? (
      //         <p style={{ color: textColor }}>N/A</p>
      //       ) : (
      //         <p style={{ color: textColor }}>
      //           {moment
      //             .utc(params.row?.Time)
      //             .local()
      //             .format("MMMM Do YYYY, h:mm:ss a")}
      //         </p>
      //       )}
      //     </Box>
      //   );
      // },
    },
  ];

  const MainContainerStyle = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      "& > div": {
        width: "100%",
        marginTop: 15,
        "&.classList": {
          "& > div": {
            marginTop: 15,
          },
        },
        "& > button": {
          padding: "6px 6px",
          margin: "0px 2px",
        },
        "& > div": {
          width: "100%",
        },
      },
    },
  }));

  const initialValues = {
    Sections: sectonList[0],
    Class: classList[0],
    date: "",
  };

  const userSchema = yup.object({
    date: yup.date().nullable(),

    // Class: yup.string().oneOf(classList, "Please select the class"),
    // Sections: yup.string().oneOf(sectonList, "Please select the section"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (btnClicked === "Present") {
        if (values?.date === "") {
          setStatusInfo((prev) => ({
            ...prev,
            statusType: "warning",
            statusText: "please fill date",
          }));
          setOpenStatus(true);
        } else {
          authAPI
            .get(
              `api/attendance-reports?userid=${userDetails?.id}&date=${values?.date}&clas=${values?.Class}&sec=${values?.Sections}&type=present`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            )
            .then((res) => {
              const newResponseData = res?.data;
              const check = newResponseData.map((item) => ({
                ...item,
                Time: moment
                  .utc(item?.Time)
                  .local()
                  .format("MMMM Do YYYY, h:mm:ss a"),
              }));
              // console.log(check);
              setShowData(check);
            })
            .catch((err) => console.log(err));
        }
      } else if (btnClicked === "Absent") {
        if (values?.date === "") {
          setStatusInfo((prev) => ({
            ...prev,
            statusType: "warning",
            statusText: "please fill date",
          }));
          setOpenStatus(true);
        } else {
          authAPI
            .get(
              `api/attendance-reports?userid=${userDetails?.id}&date=${values?.date}&clas=${values?.Class}&sec=${values?.Sections}&type=absent`,

              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            )
            .then((res) => {
              const newResponseData = res?.data;
              const check = newResponseData.map((item) => ({
                ...item,
                Time: moment
                  .utc(item?.Time)
                  .local()
                  .format("MMMM Do YYYY, h:mm:ss a"),
              }));
              // console.log(check);
              setShowData(check);
            })
            .catch((err) => console.log(err));
        }
      } else if (btnClicked === "Both") {
        if (values?.date === "") {
          setStatusInfo((prev) => ({
            ...prev,
            statusType: "warning",
            statusText: "please fill date",
          }));
          setOpenStatus(true);
        } else {
          authAPI
            .get(
              `api/attendance-reports?userid=${userDetails?.id}&date=${values?.date}&clas=${values?.Class}&sec=${values?.Sections}&type=both`,

              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            )
            .then((res) => {
              const newResponseData = res?.data;
              const check = newResponseData.map((item) => ({
                ...item,
                Time: moment
                  .utc(item?.Time)
                  .local()
                  .format("MMMM Do YYYY, h:mm:ss a"),
              }));
              // console.log(check);
              setShowData(check);
            })
            .catch((err) => console.log(err));
        }
      }
    },
  });

  useEffect(() => {
    const fetchClassData = async () => {
      const fetchdata = await authAPI.get(
        `/api/classes?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const responseData = await fetchdata?.data;

      const idMax = responseData.map((x) => x?.id);

      const allID = {
        ClassName: "All",
        id: Math.max(...idMax) + 1,
        userID: userDetails?.id,
      };

      responseData.unshift(allID);

      setClassList(responseData);
    };
    fetchClassData();
  }, []);

  useEffect(() => {
    const fetchSectionData = async () => {
      const fetchdata = await authAPI.get(
        `/api/sections?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const responseData = await fetchdata?.data;

      const idMax = responseData.map((x) => x?.id);

      const allID = {
        sectionName: "All",
        id: Math.max(...idMax) + 1,
        userID: userDetails?.id,
      };

      responseData.unshift(allID);
      setSectionList(responseData);
    };
    fetchSectionData();
  }, []);

  // useEffect(() => {
  //   const fetchShowData = async () => {
  //     const fetchdata = await authAPI.get(`/api/student-reports`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  //       },
  //     });

  //     const responseData = await fetchdata?.data?.data;

  //     const newData = responseData.map((item) => ({
  //       ...item.attributes,
  //       id: item?.id,
  //     }));
  //     setShowData(newData);
  //   };
  //   fetchShowData();
  // }, []);
  return (
    <Box m="20px">
      <Box>
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          Student Attendance Report
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography> */}
      </Box>
      <MainContainerStyle
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <form>
          <Box className="classList">
            <FormControl
              sx={{ width: "10rem", marginTop: "0.5rem", marginRight: "1rem" }}
            >
              <InputLabel id="demo-simple-select-label">Class</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Class"
                name="Class"
                value={formik.values.Class}
                label="Class"
                onChange={formik.handleChange}
                error={formik.touched.Class && Boolean(formik.errors.Class)}
                helperText={formik.touched.Class && formik.errors.Class}
              >
                {classList.map((c) => (
                  <MenuItem value={c?.ClassName} key={c?.id}>
                    {c?.ClassName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "10rem", marginTop: "0.5rem" }}>
              <InputLabel id="demo-simple-select-label">Sections</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Sections"
                name="Sections"
                value={formik.values.Sections}
                label="Sections"
                onChange={formik.handleChange}
                error={
                  formik.touched.Sections && Boolean(formik.errors.Sections)
                }
                helperText={formik.touched.Sections && formik.errors.Sections}
              >
                {sectonList.map((c) => (
                  <MenuItem value={c?.sectionName} key={c?.id}>
                    {c?.sectionName}
                  </MenuItem>
                ))}

                {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
              </Select>
            </FormControl>
            <label htmlFor="date">
              <input
                type="date"
                id="date"
                name="date"
                onChange={formik.handleChange}
                value={formik.values.date}
                style={{
                  margin: isNonMobile ? "0.5rem 1rem" : "0.5rem 0rem",
                  padding: "1rem 0.3rem",
                  border: "0.5px solid lightgray",
                  borderRadius: "5%",
                  backgroundColor: "#f9fafb",
                }}
              />
            </label>
          </Box>
          <Button
            variant="contained"
            sx={{
              width: isNonMobile ? "7rem" : "6rem",
              height: "3rem",
              margin: "0.5rem 0.3rem 0.5rem 0",
            }}
            type="submit"
            onClick={(e) => {
              setBtnClicked("Present");
              formik.handleSubmit();
            }}
          >
            Present
          </Button>
          <Button
            variant="contained"
            sx={{
              width: isNonMobile ? "7rem" : "6rem",
              height: "3rem",
              margin: "0.5rem 0.3rem 0.5rem 0",
            }}
            type="submit"
            onClick={(e) => {
              setBtnClicked("Absent");
              formik.handleSubmit();
            }}
          >
            Absent
          </Button>
          <Button
            variant="contained"
            sx={{
              width: isNonMobile ? "7rem" : "6rem",
              height: "3rem",
              margin: "0.5rem 0.3rem 0.5rem 0",
            }}
            type="submit"
            onClick={(e) => {
              setBtnClicked("Both");
              formik.handleSubmit();
            }}
          >
            Both
          </Button>
        </form>
        {/* <Box
          width="25rem"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        ></Box> */}
      </MainContainerStyle>
      <Box
        m="40px 0 0 0"
        height="58vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            border: 1,
            borderColor: `${theme.palette.grey[500]}`,
          },

          "& .name-column--cell": {
            // color: "red",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${theme.palette.grey[300]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: `${theme.palette.grey[200]}`,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: `${theme.palette.grey[300]}`,
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.grey[700]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.grey[700]} !important`,
          },
        }}
      >
        <DataGrid
          getRowClassName={(params) => {
            return params.row?.Attendance === undefined
              ? setTextColor("red")
              : setTextColor("green");
          }}
          sx={{
            "& .MuiDataGrid-cellContent": {
              color: textColor,
            },
          }}
          rows={showData}
          columns={columns}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          disableColumnFilter
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
      {openStatus && (
        <StatusField
          setOpenStatus={setOpenStatus}
          statusType={statusInfo.statusType}
          statusText={statusInfo.statusText}
        />
      )}
    </Box>
  );
};

export default StudentDaywise;
