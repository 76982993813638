import * as React from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Stack,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function DropdownButton() {
  const [open, setOpen] = React.useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#373b44",
    fontWeight: 200,
    "&:hover": {
      backgroundColor: "#464951",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      fontSize: 12,
    },
    "& + .MuiPopper-root": {
      transform: "unset !important",
      top: "100% !important",
      zIndex: 9,
      "@media (max-width: 767px)": {
        left: "-50px !important",
      },
      "& > div": {
        boxShadow: "0px 2px 10px #eee",
      },
    },
  }));
  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ position: "relative" }}>
        <ColorButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          sx={{
            bgcolor: (theme) => alpha(theme.palette.grey[700], 0.5),
            color: "white",
            textTransform: "capitalize",
            fontWeight: 200,
            "&:hover": {
              backgroundColor: "#464951",
            },
            border: "1px solid black",
          }}
          startIcon={<PersonIcon />}
          endIcon={<ArrowDropDownIcon />}
        >
          {userDetails?.username}
        </ColorButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <Link
                      to="/dashboard/add_cl"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem onClick={handleClose}>Class Manage</MenuItem>
                    </Link>
                    <Link
                      to="/dashboard/add_sc"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem onClick={handleClose}>
                        Section/Division Manage
                      </MenuItem>
                    </Link>

                    <Link
                      to="/dashboard/sms_template"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem onClick={handleClose}>SMS Template</MenuItem>
                    </Link>
                    <Link
                      to="/dashboard/school-timing"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem onClick={handleClose}>
                        Set School Timing
                      </MenuItem>
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Stack>
  );
}
