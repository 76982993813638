import React from "react";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";

const CustomizedButton = ({
  bgColor,
  textTransform,
  buttonText,
  textColor,
  hoverColor,
  handle,
  startIcon,
}) => {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    textTransform: "capitalize",
    backgroundColor: alpha(theme.palette.grey[700], 0.5),
    fontWeight: 200,
    "&:hover": {
      backgroundColor: "#464951",
    },
    boxShadow: "none",
    border: "1px solid black",
    '@media (min-width: 768px) and (max-width: 1024px)': {
      fontSize: 12,
      padding: '6px 12px',
    },
  }));
  return (
    <ColorButton
      variant="contained"
      onClick={handle}
      startIcon={startIcon === "" ? "" : startIcon}
    >
      {buttonText}
    </ColorButton>
  );
};

export default CustomizedButton;
