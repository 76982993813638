import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import SchoolIcon from "@mui/icons-material/School";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import * as yup from "yup";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import InputLabel from "@mui/material/InputLabel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SMSButton from "../button/SMSButton";
import { authAPI } from "../../axios/authAPI";

const SmsButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  "& > div": {
    paddingLeft: 0,
    margin: 5,
  },
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "& > div": {
      paddingLeft: 0,
      margin: 5,
    },
  },
  "& .heading": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& p": {
      marginBottom: 0,
      "& + p": {
        marginLeft: 10,
      },
    },
  },
}));
const StudentAbsentReport = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(null);
  const [classList, setClassList] = useState(["All"]);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [sectonList, setSectionList] = useState(["All"]);
  const [loader, setLoader] = useState(false);
  const [showData, setShowData] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const timeLine = [
    {
      value: 0,
      field: "Today",
    },
    {
      value: 1,
      field: "Yesterday",
    },
    {
      value: 2,
      field: "Last 2 days",
    },
    {
      value: 3,
      field: "Last 3 days",
    },
    {
      value: 4,
      field: "Last 4 days",
    },
    {
      value: 5,
      field: "Last 5 days",
    },
    {
      value: 6,
      field: "Last 6 days",
    },
    {
      value: 7,
      field: "Last 7 days",
    },
    {
      value: 8,
      field: "Last 8 days",
    },
    {
      value: 9,
      field: "Last 9 days",
    },
    {
      value: 10,
      field: "Last 10 days",
    },
    {
      value: 11,
      field: "Last 11 days",
    },
    {
      value: 12,
      field: "Last 12 days",
    },
    {
      value: 13,
      field: "Last 13 days",
    },
    {
      value: 14,
      field: "Last 14 days",
    },
    {
      value: 15,
      field: "Last 15 days",
    },
    {
      value: 28,
      field: "Last 28 days",
    },
    {
      value: 29,
      field: "Last 29 days",
    },
    {
      value: 30,
      field: "Last 30 days",
    },
    {
      value: 31,
      field: "Last 31 days",
    },
    {
      value: 60,
      field: "Last 60 days",
    },
    {
      value: 61,
      field: "Last 61 days",
    },
  ];
  const initialValues = {
    Sections: sectonList[0],
    Class: classList[0],
    From: "",
    To: "",
  };

  const userSchema = yup.object({
    From: yup.string().min(1, "please mention date"),
    To: yup.string().min(1, "please mention date"),

    // Class: yup.string().oneOf(classList, "Please select the class"),
    // Sections: yup.string().oneOf(sectonList, "Please select the section"),
  });

  const MainContainerStyle = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
      "& > div": {
        width: "49%",
        paddingLeft: "0 !important",
        marginTop: 10,
        "& button": {
          width: "100%",
        },
      },
    },
  }));

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (values.From === "" || values.To === "")
        return alert("please mention date");
      console.log(values);
      setLoader(true);
      authAPI
        .get(
          `/api/absent-reports?FromDate=${values?.From}&ToDate=${values?.To}&userid=${userDetails?.id}&clas=${values?.Class}&sec=${values?.Sections}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {
          // setShowData(res?.data)
          const newResponseData = res?.data;
          const check = newResponseData.map((item, idx) => ({
            ...item,
            id: idx + 1,
          }));

          // console.log(check);
          setLoader(false);
          setShowData(check);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    },
  });

  useEffect(() => {
    const fetchClassData = async () => {
      const fetchdata = await authAPI.get(
        `/api/classes?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const responseData = await fetchdata?.data;
      const idMax = responseData.map((x) => x?.id);

      const allID = {
        ClassName: "All",
        id: Math.max(...idMax) + 1,
        userID: userDetails?.id,
      };

      responseData.unshift(allID);
      setClassList(responseData);
    };
    fetchClassData();
  }, []);

  useEffect(() => {
    const fetchSectionData = async () => {
      const fetchdata = await authAPI.get(
        `/api/sections?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const responseData = await fetchdata?.data;
      const idMax = responseData.map((x) => x?.id);

      const allID = {
        sectionName: "All",
        id: Math.max(...idMax) + 1,
        userID: userDetails?.id,
      };

      responseData.unshift(allID);
      setSectionList(responseData);
    };
    fetchSectionData();
  }, []);

  // useEffect(() => {
  //   const fetchShowData = async () => {
  //     const fetchdata = await authAPI.get(
  //       `/api/absent-reports?schoolId=${userDetails?.id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  //         },
  //       }
  //     );

  //     const responseData = await fetchdata?.data;

  //     const newData = responseData.map((item) => ({
  //       ...item.attributes,
  //       id: item?.id,
  //     }));

  //     setShowData(newData);
  //   };
  //   fetchShowData();
  // }, []);

  const columns = [
    {
      field: "id",
      headerName: "Sr",
    },

    {
      field: "Name",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      width: 250,
    },

    {
      field: "Admission_No",
      headerName: "Admission No",
      width: 250,
      cellClassName: "name-column-cell",
    },

    {
      field: "IcardNo",
      headerName: "Icard No",
      width: 250,
    },

    {
      field: "Class",
      headerName: "Class",
      width: 130,
    },

    {
      field: "Sections",
      headerName: "Sections",
      width: 130,
    },

    {
      field: "Contact_No",
      headerName: "Contact Number",
      width: 250,
    },

    {
      field: "RFID_No",
      headerName: "RFID",
      width: 250,
    },
  ];

  return (
    <Box m="0 20px 20px 20px">
      <Box>
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          Student Absent Report
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography> */}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          display="flex"
          gap="0.75rem"
          flexDirection={isNonMobile ? "row" : "column"}
        >
          <FormControl
            sx={{
              width: isNonMobile ? "10rem" : "100%",
              marginTop: "0.5rem",
              marginRight: "1rem",
            }}
          >
            <InputLabel id="demo-simple-select-label">Class</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="Class"
              name="Class"
              value={formik.values.Class}
              label="Sections"
              onChange={formik.handleChange}
              // error={formik.touched.Class && Boolean(formik.errors.Class)}
              // helperText={formik.touched.Class && formik.errors.Class}
            >
              {classList.map((c) => (
                <MenuItem value={c?.ClassName} key={c?.id}>
                  {c?.ClassName}
                </MenuItem>
              ))}

              {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl
            sx={{ width: isNonMobile ? "10rem" : "100%", marginTop: "0.5rem" }}
          >
            <InputLabel id="demo-simple-select-label">Sections</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="Sections"
              name="Sections"
              value={formik.values.Sections}
              label="Sections"
              onChange={formik.handleChange}
              // error={formik.touched.Sections && Boolean(formik.errors.Sections)}
              // helperText={formik.touched.Sections && formik.errors.Sections}
            >
              {sectonList.map((c) => (
                <MenuItem value={c?.sectionName} key={c?.id}>
                  {c?.sectionName}
                </MenuItem>
              ))}

              {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
          {/* </Box> */}
          {/* <Box className="middleInputContainer"> */}
          <label htmlFor="From">
            <Box display="flex" flexDirection={isNonMobile ? "row" : "column"}>
              <p style={{ marginTop: isNonMobile ? "1.5rem" : "0rem" }}>From</p>
              <input
                type="date"
                id="From"
                name="From"
                onChange={formik.handleChange}
                value={formik.values.From}
                style={{
                  margin: isNonMobile ? "0.5rem 1rem" : "0.5rem 0rem",
                  padding: "1rem 0.3rem",
                  border: "0.5px solid lightgray",
                  borderRadius: "5%",
                  backgroundColor: "#f9fafb",
                }}
              />
            </Box>
          </label>
          <label htmlFor="To">
            <Box display="flex" flexDirection={isNonMobile ? "row" : "column"}>
              <p style={{ marginTop: isNonMobile ? "1.5rem" : "0rem" }}>To</p>
              <input
                type="date"
                id="To"
                name="To"
                onChange={formik.handleChange}
                value={formik.values.To}
                style={{
                  margin: isNonMobile ? "0.5rem 1rem" : "0.5rem 0rem",
                  padding: "1rem 0.3rem",
                  border: "0.5px solid lightgray",
                  borderRadius: "5%",
                  backgroundColor: "#f9fafb",
                }}
              />
            </Box>
          </label>
          {/* </Box> */}
          {/* <Box> */}
          <LoadingButton
            variant="contained"
            loading={loader}
            loadingPosition="start"
            type="submit"
            sx={{ width: "7rem", height: "3rem", marginTop: "0.5rem" }}
          >
            SUBMIT
          </LoadingButton>
        </Box>
      </form>
      {/* <SmsButtonContainer
        display="flex"
        flexDirection="row"
        margin="20px 0 0 0"
      >
        <Box className="heading">
          <Typography
            color={theme.palette.grey[600]}
            sx={{ m: "0 0 5px 0" }}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            Student Absent:
          </Typography>
          <Typography
            color="white"
            sx={{
              backgroundColor: theme.palette.error.main,
              padding: "1px 5px",
              borderRadius: "15px",
            }}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            3444
          </Typography>
        </Box>
        <MainContainerStyle display="flex" flexDirection="row">
          <SMSButton
            subText="Make Present (with SMS)"
            backgroundColorButton={theme.palette.grey[300]}
            colorText="black"
            hoverBG={theme.palette.grey[400]}
            hoverText="black"
          />
          <SMSButton
            subText="Make Present (No SMS)"
            backgroundColorButton={theme.palette.grey[300]}
            colorText="black"
            hoverBG={theme.palette.grey[400]}
            hoverText="black"
          />

          <SMSButton
            subText="Disable"
            backgroundColorButton={theme.palette.error.main}
            colorText="white"
            hoverBG={theme.palette.error.dark}
            hoverText="white"
          />

          <SMSButton
            subText="Enable"
            backgroundColorButton={theme.palette.success.main}
            colorText="white"
            hoverBG={theme.palette.success.dark}
            hoverText="white"
          />
          <SMSButton
            subText="Send SMS"
            backgroundColorButton={theme.palette.info.main}
            colorText="white"
            hoverBG={theme.palette.info.dark}
            hoverText="white"
          />
        </MainContainerStyle>
      </SmsButtonContainer> */}
      <Box
        m="20px 0 0 0"
        height="58vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            // color: "red",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${theme.palette.grey[300]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: `${theme.palette.grey[200]}`,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: `${theme.palette.grey[300]}`,
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.grey[700]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.grey[700]} !important`,
          },
        }}
      >
        <DataGrid
          rows={showData}
          columns={columns}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          disableColumnFilter
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default StudentAbsentReport;
