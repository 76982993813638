
export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
    <defs>
      <linearGradient id="linear-gradient" x1="0.528" y1="1" x2="0.345" y2="0.127" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#0b62a2" />
        <stop offset="0.762" stopColor="#1bb9e9" />
        <stop offset="0.829" stopColor="#1bb9e9" />
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.472" x2="0.655" y2="0.127" xlinkHref="#linear-gradient" />
    </defs>
    <g id="Group_710" data-name="Group 710" transform="translate(-2989 -2835)">
      <rect id="Rectangle_389" data-name="Rectangle 389" width="32" height="32" transform="translate(2989 2835)" fill="none" />
      <g id="final_logo" transform="translate(2798.001 2628.758)">
        <g id="Group_280" data-name="Group 280" transform="translate(191 207.856)">
          <path id="Path_704" data-name="Path 704" d="M29.628,1.471A6.112,6.112,0,0,0,23.144.549c-2.127.823-4.5,2.91-4.839,11.306-3.754,1.058-4,1.084-4,1.084l.259-5.951s.387-.307.678-.575a2.676,2.676,0,0,0,.6-.875,3.345,3.345,0,0,0,.271-1.688A3.858,3.858,0,0,0,15.241,1.7,4.051,4.051,0,0,0,13.056.557a4.37,4.37,0,0,0-2.7.6A3.6,3.6,0,0,0,9,3.336a4.326,4.326,0,0,0,.435,2.672A4.089,4.089,0,0,0,11.2,7.375l-.307,6.553L5.909,15.088S.688,15.839.144,20.617A7.38,7.38,0,0,0,.7,24.839a7.224,7.224,0,0,0,4.925,3.837c2.66.479,7.313-.618,7.928-5.124a41.215,41.215,0,0,0,.478-7.031L17.888,15.4l-.3,8.819-.112,3.4s-.053.289.409.307,2.547.042,2.547.042a.5.5,0,0,0,.415-.477c.007-.049.062-1.438.134-3.3.146-3.788.359-9.532.359-9.532s6.844-1.09,9.3-3.889S32.12,3.245,29.628,1.471ZM10.374,22.8a2.953,2.953,0,0,1-3.022,2.69,3.534,3.534,0,0,1-3.862-4.3c.344-2.343,2.091-2.426,3.238-2.737s3.924-1,3.924-1ZM27.239,9.377a24.3,24.3,0,0,1-5.485,1.89,35.24,35.24,0,0,1,.84-5.434A3.1,3.1,0,0,1,26.164,3.5a2.87,2.87,0,0,1,1.943,1.231,3.741,3.741,0,0,1,.6,1.811,2.755,2.755,0,0,1-1.471,2.838Z" transform="translate(-0.068 -0.007)" fill="#2eaee9" />
        </g>
        <path id="Path_705" data-name="Path 705" d="M512.632,88.064c.2-1.155-1.04-2.664-1.04-2.664l-2.717,2.049a3.783,3.783,0,0,1,.454.661,4.377,4.377,0,0,1,.389,1.522,2.736,2.736,0,0,1-.643,2.1,15.045,15.045,0,0,0,1.845-1.182A5.726,5.726,0,0,0,512.632,88.064Z" transform="translate(-290.065 124.843)" fillRule="evenodd" fill="url(#linear-gradient)" />
        <path id="Path_706" data-name="Path 706" d="M0,2.757C.217,1.951.842,0,.842,0L3.79,2.057s-.207.745-.3,1.094a8.178,8.178,0,0,0-.328,1.31,2.755,2.755,0,0,0,.638,2.1A15.541,15.541,0,0,1,1.963,5.375,6.932,6.932,0,0,1,0,2.757Z" transform="translate(198.37 221.766) rotate(60)" fillRule="evenodd" fill="url(#linear-gradient-2)" />
      </g>
    </g>
  </svg>
)