// routes
import { I18nextProvider } from 'react-i18next';
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import i18next from './i18n';
import CContextProvider from './utils/CContextProvider';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <I18nextProvider i18n={i18next}>
      <CContextProvider>
        <ThemeProvider>
          <ScrollToTop />
          <Router />
        </ThemeProvider>
      </CContextProvider>
    </I18nextProvider>
  );
}
