import React from "react";

import { Box, Typography, useTheme, styled } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import DashboardWidget from "./Widget/DashboardWidget";

const DashboardWidgetContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
});

const DashboardManual = () => {
  const theme = useTheme();
  return (
    <Box m="0 20px 20px 20px">
      <Box mb="30px">
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          SMS Notifcations
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
      {subtitle}
    </Typography> */}
      </Box>

      <DashboardWidgetContainer>
        <DashboardWidget />
      </DashboardWidgetContainer>
    </Box>
  );
};

export default DashboardManual;
