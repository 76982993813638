import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Typography,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import { styled, alpha } from "@mui/material/styles";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import SchoolIcon from "@mui/icons-material/School";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { authAPI } from "../../axios/authAPI";
import StatusField from "../statusField/StatusField";

const ModalEditStudent = ({
  openModal,
  handleClose,
  selectedData,
  setFlag,
  flag,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isNonMobile ? 1000 : 350,
    height: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
  };
  const [classInfo, setClass] = useState("");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [openStatus, setOpenStatus] = useState(false);
  const [statusType, setStatusType] = useState();
  const [statusText, setStatusText] = useState();
  const [classList, setClassList] = useState(["I"]);
  const [sectonList, setSectionList] = useState(["A"]);

  const initialValues = {
    Name: selectedData?.Name,
    email: selectedData?.email === null ? "" : selectedData?.email,
    RFID_No: selectedData?.RFID_No,
    IcardNo: selectedData?.IcardNo,
    Contact_No: selectedData?.Contact_No,
    Admission_No: selectedData?.Admission_No,
    Alt_No: selectedData?.Alt_No === null ? "" : selectedData?.Alt_No,

    Sections: selectedData?.Sections,
    Class: selectedData?.Class,

    status: selectedData?.Status,
    userID: userDetails?.id,
  };
  console.log(initialValues);
  // const initialValues = selectedData !== undefined && selectedData;

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const userSchema = yup.object({
    Name: yup.string().required("required"),
    email: yup.string().email("invalid email"),
    RFID_No: yup.string().required("required"),
    IcardNo: yup.string().required("required"),
    Contact_No: yup.number(),
    Admission_No: yup.string().required("required"),
    Alt_No: yup.number(),
    // Class: yup.string().oneOf(classN, "Please select the class"),
    // Sections: yup.string().oneOf(sections, "Please select the section"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      values.Contact_No = parseInt(values.Contact_No, 10);
      values.Alt_No = parseInt(values.Alt_No, 10);
      // values.schoolId = parseInt(userDetails?.id, 10);
      // values.school = parseInt(userDetails?.id, 10);
      // console.log(values);
      authAPI
        .put(
          `/api/add-students/${selectedData?.id}`,
          { data: values },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {
          setOpenStatus(true);
          setStatusType("success");
          setStatusText("Student has been updated successfully");
          setFlag(!flag);
          setTimeout(() => {
            handleClose();
          }, 2000);
        })
        .catch((err) => {
          setOpenStatus(true);
          setStatusType("warning");
          setStatusText(
            `${err?.response?.data?.error?.details?.errors[0].path[0]} must be unique`
          );
        });
    },
  });

  useEffect(() => {
    const fetchClassData = async () => {
      const fetchdata = await authAPI.get(
        `/api/classes?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      const responseData = await fetchdata?.data;
      setClassList(responseData);
    };
    fetchClassData();
  }, []);

  useEffect(() => {
    const fetchSectionData = async () => {
      const fetchdata = await authAPI.get(
        `/api/sections?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      const responseData = await fetchdata?.data;
      setSectionList(responseData);
    };
    fetchSectionData();
  }, []);
  const theme = useTheme();

  return (
    <Box m="20px">
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            variant="h4"
            color={theme.palette.grey[600]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <SchoolIcon
              sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
            />
            Edit student
          </Typography>
          <Box>
            <form onSubmit={formik.handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  id="Name"
                  name="Name"
                  label="Name"
                  value={formik.values.Name}
                  onChange={formik.handleChange}
                  error={formik.touched.Name && Boolean(formik.errors.Name)}
                  helperText={formik.touched.Name && formik.errors.Name}
                  sx={{ gridColumn: "span 2" }}
                  autoComplete="off"
                />

                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  id="RFID_No"
                  name="RFID_No"
                  label="RFID_NO"
                  value={formik.values.RFID_No}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.RFID_No && Boolean(formik.errors.RFID_No)
                  }
                  helperText={formik.touched.RFID_No && formik.errors.RFID_No}
                  sx={{ gridColumn: "span 2" }}
                  autoComplete="off"
                />

                <TextField
                  fullWidth
                  id="IcardNo"
                  name="IcardNo"
                  label="IcardNo"
                  value={formik.values.IcardNo}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.IcardNo && Boolean(formik.errors.IcardNo)
                  }
                  helperText={formik.touched.IcardNo && formik.errors.IcardNo}
                  sx={{ gridColumn: "span 2" }}
                  autoComplete="off"
                />
                <TextField
                  fullWidth
                  id="Contact_No"
                  name="Contact_No"
                  label="Contact No"
                  value={formik.values.Contact_No}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.Contact_No &&
                    Boolean(formik.errors.Contact_No)
                  }
                  helperText={
                    formik.touched.Contact_No && formik.errors.Contact_No
                  }
                  sx={{ gridColumn: "span 2" }}
                  autoComplete="off"
                />

                <TextField
                  fullWidth
                  id="Admission_No"
                  name="Admission_No"
                  label="Admission No"
                  value={formik.values.Admission_No}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.Admission_No &&
                    Boolean(formik.errors.Admission_No)
                  }
                  helperText={
                    formik.touched.Admission_No && formik.errors.Admission_No
                  }
                  sx={{ gridColumn: "span 2" }}
                  autoComplete="off"
                />
                <TextField
                  fullWidth
                  id="Alt_No"
                  name="Alt_No"
                  label="Alt no"
                  value={formik.values.Alt_No}
                  onChange={formik.handleChange}
                  error={formik.touched.Alt_No && Boolean(formik.errors.Alt_No)}
                  helperText={formik.touched.Alt_No && formik.errors.Alt_No}
                  sx={{ gridColumn: "span 2" }}
                  autoComplete="off"
                />

                <Box
                  sx={{
                    gridColumn: "span 2",
                  }}
                >
                  <Box display="flex" flexDirection="row">
                    <FormControl sx={{ width: "5rem", marginRight: "2rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Class
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="Class"
                        name="Class"
                        value={formik.values.Class}
                        label="Class"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.Class && Boolean(formik.errors.Class)
                        }
                        helperText={formik.touched.Class && formik.errors.Class}
                      >
                        {classList.map((c) => (
                          <MenuItem value={c?.ClassName} key={c?.id}>
                            {c?.ClassName}
                          </MenuItem>
                        ))}

                        {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: "5rem", marginRight: "2rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Sections
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="Sections"
                        name="Sections"
                        value={formik.values.Sections}
                        label="Sections"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.Sections &&
                          Boolean(formik.errors.Sections)
                        }
                        helperText={
                          formik.touched.Sections && formik.errors.Sections
                        }
                      >
                        {sectonList.map((c) => (
                          <MenuItem value={c?.sectionName} key={c?.id}>
                            {c?.sectionName}
                          </MenuItem>
                        ))}

                        {/* <MenuItem value={"20"}>Twenty</MenuItem>
                <MenuItem value={"30"}>Thirty</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ gridColumn: isNonMobile ? "span 2" : "span 2" }}
                >
                  Save Changes
                </Button>
              </Box>
            </form>
          </Box>
          {openStatus && (
            <StatusField
              setOpenStatus={setOpenStatus}
              statusType={statusType}
              statusText={statusText}
            />
          )}
        </Box>

        {/* </Formik> */}
      </Modal>
    </Box>
  );
};

export default ModalEditStudent;
