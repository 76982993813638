import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";

import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

export default function StatusField({ setOpenStatus, statusType, statusText }) {
  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={{ width: 300, position: "absolute", top: 100, right: 30 }}>
      <Alert
        variant="filled"
        action={
          <IconButton
            aria-label="close"
            // color="inherit"
            size="small"
            onClick={() => {
              setOpenStatus(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        // sx={{ mb: 2 }}
        severity={statusType}
      >
        {statusText}
      </Alert>
    </Box>
  );
}
