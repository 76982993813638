import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  alpha,
} from "@mui/material";
import AutorenewSharpIcon from "@mui/icons-material/AutorenewSharp";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { authAPI } from "../../axios/authAPI";

const mockData = [
  {
    id: 1,
    Class: "VI",
    Total: "100",
    Present: "50",
    Absent: "50",
    Status: "Status",
  },
];
const DashboardTable = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(null);
  const [dashboardData, getDashboardData] = useState([]);
  const [statusData, setStatusData] = useState({ present: 0, total: 0 });

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    const getStudentData = async () => {
      const checkData = await authAPI.get(
        `api/dashboards?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const newResponseData = await checkData?.data?.data;
      if (newResponseData.length === 0) {
        getDashboardData(newResponseData);
      } else {
        setStatusData((prev) => ({
          total: newResponseData
            .map((item) => parseInt(item?.total, {}))
            .reduce((acc, amount) => acc + amount),
          present: newResponseData
            .map((item) => parseInt(item?.present, {}))
            .reduce((acc, amount) => acc + amount),
        }));

        const check = newResponseData.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        getDashboardData(check);
      }

      // setStatusData((prev) => ({
      //   total: newResponseData
      //     .map((item) => parseInt(item?.total, {}))
      //     .reduce((acc, amount) => acc + amount),
      //   present: newResponseData
      //     .map((item) => parseInt(item?.present, {}))
      //     .reduce((acc, amount) => acc + amount),
      // }));

      // const check = newResponseData.map((item, index) => ({
      //   ...item,
      //   id: index + 1,
      // }));

      // getDashboardData(check);
    };

    getStudentData();
  }, []);

  const columns = [
    { field: "id", headerName: "Sr", width: 100 },
    {
      field: "class",
      headerName: "Class",
      headerAlign: "left",
      align: "left",
      width: 230,
    },
    {
      field: "total",
      headerName: "Total",
      width: 250,
      cellClassName: "name-column-cell",
    },
    {
      field: "present",
      headerName: "Present",
      width: 250,
    },
    {
      field: "absent",
      headerName: "Absent",
      width: 250,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const status = (params.row.present / params.row.total) * 100;
        // const status = 92.22232424;

        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              variant="determinate"
              value={status}
              size="3.1rem"
            />
            <Typography position="absolute" fontSize="small">
              {status.toFixed(1)}%
            </Typography>
          </Box>
        );
      },
    },
  ];
  return (
    <Box m="20px">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <AutorenewSharpIcon sx={{ fontSize: 30 }} />
        <Typography
          sx={{
            bgcolor: (theme) => alpha(theme.palette.primary.dark, 0.8),
            // padding: "0% 1%",
            padding: "0.2% 1% 0 1%",
            borderRadius: "10px",
            color: "white",
          }}
        >
          {statusData?.present} out of {statusData?.total}
        </Typography>
      </Box>
      <Box
        m="10px 0 0 0"
        height="58vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            border: 1,
            borderColor: `${theme.palette.grey[400]} !important`,
            padding: "1rem 0.5rem !important",
          },
          "& .name-column--cell": {
            // color: "red",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${theme.palette.grey[300]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: `${theme.palette.grey[200]}`,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: `${theme.palette.grey[300]}`,
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.grey[700]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.grey[700]} !important`,
          },
        }}
      >
        <DataGrid
          rows={dashboardData}
          columns={columns}
          getRowHeight={() => "auto"}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          disableColumnFilter
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default DashboardTable;
