import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useTheme,
  ClickAwayListener,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// components
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

import Iconify from "../../components/Iconify";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import CustomizedButton from "./CustomizedButton";
import DropdownButton from "./DropdownButton";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 270;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 72;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  // backdropFilter: "blur(6px)",
  // WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: theme.palette.grey[900],
  width: "100%",
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  "& .logo": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "auto",
    "& h6": {
      "@media (min-width: 1025px) and (max-width: 1199px)": {
        fontSize: "16px !important",
      },
    },
  },
  "& .header-right": {
    display: "flex",
    "& button": {
      marginLeft: 5,
    },
    "& .usericon": {
      display: "none",
    },
    "@media (max-width: 767px)": {
      display: "none",
      "&.open": {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        width: 180,
        top: "100%",
        right: 0,
        background: "#fff",
        boxShadow: "0px 2px 10px #eee",
      },
      "& button": {
        padding: "10px 18px",
        fontSize: 12,
        marginLeft: 0,
        marginBottom: 0,
        backgroundColor: "transparent",
        color: "#000",
        border: 0,
        justifyContent: "flex-start",
        borderBottom: "1px solid #eee",
        borderRadius: 0,
        width: "100%",
        "& .MuiButton-endIcon": {
          display: "flex",
          marginLeft: "auto",
        },
      },
      "& > div": {
        "& > div": {
          width: "100%",
        },
      },
    },
  },
  "& .usericon": {
    display: "none",
    "@media (max-width: 767px)": {
      display: "flex",
      cursor: "pointer",
    },
  },
  [theme.breakpoints.down("lg")]: {
    padding: "10px 10px",
    "& .logo": {
      "& h6": {
        fontSize: 13,
      },
    },
  },
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 3.75, 0, 2.5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({
  onOpenSidebar,
  handleSidebar,
  isNonMobileScreen,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [openDropdown, setOpenDropdown] = useState(false);

  const mobileDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isNonMobileScreen) {
      handleSidebar();
    }
    setOpenDropdown(!openDropdown);
  };
  const handle = () => {
    setOpenDropdown(false);
  };
  useEffect(() => {
    setOpenDropdown(false);
  }, []);

  useEffect(() => {
    onOpenSidebar();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onOpenSidebar();
          }}
          sx={{ mr: 1, color: "#fff" }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        {/* <Box sx={{ flexGrow: 1 }} /> */}

        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="flex-end"
          width="100%"
        >
          <Box className="logo">
            <Typography variant="h6" color={theme.palette.grey[300]}>
              Attendance Management Systems
            </Typography>
          </Box>
          <Box className="usericon" onClick={mobileDropdown}>
            <AccountCircleOutlinedIcon />
            <KeyboardArrowDownOutlinedIcon />
          </Box>
          <ClickAwayListener onClickAway={handle}>
            <Box className={`header-right ${openDropdown ? "open" : null}`}>
              <CustomizedButton buttonText="SMS Credit: 0" handle={handle} />
              {/* <CustomizedButton buttonText="iActivity credit: 0" /> */}
              <DropdownButton />
              <CustomizedButton
                buttonText="Logout"
                startIcon={<LogoutIcon />}
                handle={handleLogout}
              />
            </Box>
          </ClickAwayListener>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
